<template>
  <div
    class="sf-product-card"
    :class="{ 'has-colors': colors.length }"
    data-testid="product-card"
    :data-sku="sku"
    :data-index="dataIndex"
    v-observe-visibility="{
          callback: visibilityChanged,
          intersection: {
            rootMargin: '0px'
          }
        }"
  >
    <div class="sf-product-card__image-wrapper">
      <slot
        name="image"
        v-bind="{
          image,
          title,
          link,
          imageHeight,
          imageWidth,
          imageTag,
          nuxtImgConfig,
        }"
      >
        <SfButton
          :link="link"
          class="sf-button--pure sf-product-card__link"
          data-testid="product-link"
          aria-label="Go To Product"
          v-on="$listeners"
        >
          <template v-if="Array.isArray(image)">
            <SfImage
              v-for="(picture, key) in image.slice(0, 2)"
              :key="key"
              class="sf-product-card__picture"
              :class="key !== 0 ? 'sf-product-card__image-hover' : 'sf-product-card__image-main'"
              :src="picture"
              :placeholder="placeholder"
              :alt="alt"
              :width="imageWidth"
              :height="imageHeight"
              image-tag="img"
            />
          </template>
          <SfImage
            v-else
            class="sf-product-card__image"
            :src="image"
            :alt="alt"
            :width="imageWidth"
            :height="imageHeight"
            image-tag="img"
          />
          <slot name="badge" v-bind="{ badge }">
            <div class="sf-product-card__badge-container" v-if="badge.length">
              <SfBadge v-for="badgeItem in badge" key="badgeItem"
                       class="sf-product-card__badge"
                       :class="[{ 'display-none': !badgeItem }]"
              >{{ badgeItem }}
              </SfBadge>
            </div>
          </slot>
        </SfButton>
      </slot>
      <slot name="colors" v-bind="{ colors }">
        <SfColorPicker
          :class="{ 'display-none': !colors.length }"
          class="sf-product-card__colors mobile-only"
          label="Choose color"
          :is-open="openColorPicker"
          @click:toggle="toggleColorPicker"
        >
          <SfColor
            v-for="(color, i) in colors"
            :key="color.value"
            :color="color.color"
            :selected="color.selected"
            class="sf-product-card__color"
            :class="{ 'display-none': i > 3 && showBadge }"
            @click="handleSelectedColor(i)"
          />
          <SfBadge
            v-if="showBadge"
            class="sf-product-card__colors-badge color-secondary"
          >
            {{ `+${colors.length - 4}` }}
          </SfBadge>
        </SfColorPicker>
        <SfColorPicker
          :class="{ 'display-none': !colors.length }"
          class="sf-product-card__colors desktop-only"
          label="Choose color"
          :is-open="true"
        >
          <SfColor
            v-for="(color, i) in colors"
            :key="color.value"
            :color="color.color"
            :selected="color.selected"
            class="sf-product-card__color"
            :class="{ 'display-none': i > 3 && showBadge }"
            @click="handleSelectedColor(i)"
          />
          <SfBadge
            v-if="showBadge"
            class="sf-product-card__colors-badge color-secondary"
          >
            {{ `+${colors.length - 4}` }}
          </SfBadge>
        </SfColorPicker>
      </slot>
      <slot v-if="isInside" name="wishlist-icon" v-bind="{ currentWishlistIcon }">
        <SfButton
          :title="isInWishlist ? $t('Remove from Wishlist') : $t('Add to Wishlist')"
          :aria-label="`${ariaLabel} ${title}`"
          :class="[wishlistIconClasses]"
          data-testid="product-wishlist-button"
          @click="toggleIsInWishlist"
        >
          <SvgImage
            v-if="currentWishlistIcon"
            class="sf-wishlist-icon--inside"
            :icon="currentWishlistIcon"
            :label="$t('Wishlist')"
            width="16"
            height="24"
          />
        </SfButton>
      </slot>
    </div>
    <div class="sf-product-card__title-wishlist-wrapper">
      <slot name="title" v-bind="{ title, link }">
        <SfButton
          :link="link"
          class="sf-button--pure sf-product-card__link"
          data-testid="product-link"
          v-on="$listeners"
        >
        <span class="sf-product-card__title" :title="title">
          {{ title }}
        </span>
        </SfButton>
      </slot>
      <slot v-if="!isInside" name="wishlist-icon" v-bind="{ currentWishlistIcon }">
        <SfButton
          :title="isInWishlist ? $t('Remove from Wishlist') : $t('Add to Wishlist')"
          :aria-label="`${ariaLabel} ${title}`"
          :class="[wishlistIconClasses]"
          data-testid="product-wishlist-button"
          @click="toggleIsInWishlist"
        >
          <SvgImage
            v-if="currentWishlistIcon"
            class="sf-wishlist-icon"
            :icon="currentWishlistIcon"
            :label="$t('Wishlist')"
            width="16"
            height="24"
          />
        </SfButton>
      </slot>
    </div>
    <slot name="short_description" v-bind="{ short_description }">
      <p class="sf-product-card__short-description"
         :class="{ 'display-none': !short_description }">
        {{ short_description }}
      </p>
    </slot>
    <slot name="tag_line" v-bind="{ tag_line }">
      <p class="sf-product-card__tag_line"
         :class="{ 'display-none': !tag_line }">
        {{ tag_line }}
      </p>
    </slot>
    <div class="sf-product-card__bottom-wrapper">
      <slot name="price" v-bind="{ specialPrice, regularPrice }">
        <SfPrice
          :class="{ 'display-none': !regularPrice }"
          class="sf-product-card__price"
          :regular="regularPrice"
          :special="specialPrice"
        />
      </slot>
      <div
        v-if="isQuickShop"
        class="sf-product-card__cart quick-shop">
        <SfButton
          class="quick-shop__btn"
          @click="handleQuickShop"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
            <path
              d="M6.316 12.105C6.316 5.23 10.658 1.25 15 1.25s8.684 3.98 8.684 10.855M1.25 10.653v17.378c0 .397.324.719.724.719h26.052c.4 0 .724-.322.724-.72V10.654a.721.721 0 0 0-.724-.719H1.974c-.4 0-.724.322-.724.72Z"
              stroke="#0F1319"
              stroke-linecap="round"
              stroke-width="1.5"
            />
            <path
              d="M 32 25.5 A 6.5 6.5 0 0 1 25.5 32 A 6.5 6.5 0 0 1 19 25.5 A 6.5 6.5 0 0 1 25.5 19 A 6.5 6.5 0 0 1 32 25.5 Z"
              fill="#E6E6E5"
            />
            <path
              d="M25.067 25.067v-2.6a.433.433 0 0 1 .866 0v2.6h2.6a.433.433 0 0 1 0 .866h-2.6v2.6a.433.433 0 0 1-.866 0v-2.6h-2.6a.433.433 0 0 1 0-.866h2.6Z"
              fill="#1D1F22"
            />
          </svg>
        </SfButton>
      </div>
    </div>
    <slot name="reviews" v-bind="{ maxRating, scoreRating }">
      <div
        :class="{ 'display-none': !scoreRating }"
        class="sf-product-card__reviews"
      >
        <SfRating
          v-if="typeof scoreRating === 'number'"
          class="sf-product-card__rating"
          :max="maxRating"
          :score="scoreRating"
        />
        <SfButton
          :class="{ 'display-none': !reviewsCount }"
          :aria-label="`Read ${reviewsCount} reviews about ${title}`"
          class="sf-button--pure sf-product-card__reviews-count"
          data-testid="product-review-button"
          @click="$emit('click:reviews')"
        >
          ({{ reviewsCount }})
        </SfButton>
      </div>
    </slot>
    <slot name="remove-from-wishlist-button" v-bind="{ removeFromWishlistButton }">
      <SfButton
        :class="{ 'display-none': !removeFromWishlistButton }"
        class="sf-button--pure sf-product-card__remove-from-wishlist"
        @click="$emit('click:wishlist')"
      >
        {{ $i18n.t('Remove from wishlist') }}
      </SfButton>
    </slot>
  </div>
</template>
<script>
import {
  SfIcon,
  SfPrice,
  SfRating,
  SfImage,
  SfCircleIcon,
  SfBadge,
  SfButton,
  SfColorPicker,
  SfColor,
  SvgImage,
} from '~/components';

import imagePlaceholder from '~/styles/images/product_placeholder.svg';

import { ObserveVisibility } from 'vue-observe-visibility';
import Vue from 'vue';

Vue.directive('observe-visibility', ObserveVisibility);

export default {
  name: 'SfProductCard',
  components: {
    SfPrice,
    SfRating,
    SfIcon,
    SfImage,
    SfCircleIcon,
    SfBadge,
    SfButton,
    SfColorPicker,
    SfColor,
    SvgImage,
  },
  props: {
    sku: {
      type: String,
      default: '',
    },
    dataIndex: {
      type: [Number, String],
      default: '',
    },
    image: {
      type: [Array, Object, String],
      default: '',
    },
    imageWidth: {
      type: [Number, String],
      default: null,
    },
    imageHeight: {
      type: [Number, String],
      default: null,
    },
    placeholder: {
      type: String,
      default: imagePlaceholder,
    },
    badge: {
      type: Array,
      default: () => [],
    },
    badgeColor: {
      type: String,
      default: '',
    },
    colors: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    short_description: {
      type: String,
      default: '',
    },
    tag_line: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: null,
    },
    /**
     * Link element tag
     * @deprecated will be removed in 1.0.0 use slot to replace content
     */
    linkTag: {
      type: String,
      default: undefined,
    },
    scoreRating: {
      type: [Number, Boolean],
      default: false,
    },
    reviewsCount: {
      type: [Number, Boolean],
      default: false,
    },
    maxRating: {
      type: [Number, String],
      default: 5,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: 'addToWishlist',
    },
    isInWishlistIcon: {
      type: [String, Array],
      default: 'addedToWishlist',
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    removeFromWishlistButton: {
      type: Boolean,
      default: false,
    },
    showAddToCartButton: {
      type: Boolean,
      default: false,
    },
    isAddedToCart: {
      type: Boolean,
      default: false,
    },
    isInside: {
      type: Boolean,
      default: false,
    },
    addToCartDisabled: {
      type: Boolean,
      default: false,
    },
    imageTag: {
      type: String,
      default: 'img',
    },
    isQuickShop: [Boolean, Number],
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['visibilityChanged', 'quick-shop-open'],
  data() {
    return {
      isAddingToCart: false,
      openColorPicker: false,
    };
  },
  computed: {
    currentWishlistIcon() {
      return this.isInWishlist ? this.isInWishlistIcon : this.wishlistIcon;
    },
    showAddedToCartBadge() {
      return !this.isAddingToCart && this.isAddedToCart;
    },
    ariaLabel() {
      return this.isInWishlist ? 'Remove from wishlist' : 'Add to wishlist';
    },
    wishlistIconClasses() {
      const defaultClass = 'sf-button--pure sf-product-card__wishlist-icon';
      const isWishlistIconInside = `${this.isInside ? 'sf-product-card__wishlist-icon--inside' : 'sf-product-card__wishlist-icon'}`;
      return `${defaultClass} ${isWishlistIconInside} ${this.isInWishlist ? 'on-wishlist' : ''}`;
    },
    showBadge() {
      return this.colors.length > 5;
    },
    alt() {
      return this.title ? this.title : ' ';
    }
  },
  methods: {
    toggleIsInWishlist() {
      this.$emit('click:wishlist', !this.isInWishlist);
    },
    onAddToCart(event) {
      event.preventDefault();
      this.isAddingToCart = true;
      setTimeout(() => {
        this.isAddingToCart = false;
      }, 1000);
      this.$emit('click:add-to-cart');
    },
    handleSelectedColor(colorIndex) {
      if(this.colors.length > 0) {
        this.colors.map((color, i) => {
          if(colorIndex === i) {
            this.$emit('click:colors', color);
            this.openColorPicker = false;
          }
        });
      }
    },
    toggleColorPicker() {
      this.openColorPicker = !this.openColorPicker;
    },
    visibilityChanged(isVisible, entry) {
      this.$emit('visibilityChanged', {isVisible: isVisible, entry: entry});
    },
    handleQuickShop() {
      this.$emit('quick-shop-open', {
        currentSku: this.sku,
        regularPriceVal: this.regularPrice,
        specialPriceVal: this.specialPrice
      });
    }
  },
};
</script>
<style lang="scss">
@import "~@/styles/components/organisms/SfProductCard.scss";

.quick-shop__btn {
  padding: 0;
  margin: var(--product-card-margin, 0);
}

.sf-product-card__bottom-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}
</style>
