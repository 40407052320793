import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { StripePublicKey } from '~/modules/GraphQL/types';

import type { Ref } from '@nuxtjs/composition-api';

export interface useStripePublicKeyInterface {

  load() : Promise<StripePublicKey>;

  loading: Readonly<Ref<boolean>>;

  error: Readonly<Ref<Error | null>>;
}


export function useStripePublicKey() : useStripePublicKeyInterface  {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref(null);

  const load = async () : Promise<StripePublicKey> => {
    let result = '';

    try {
      loading.value = true;

      // @ts-ignore
      const { data } = await app.$vsf.$magento.api.getStripePublicKey();

      // @ts-ignore
      result = data?.getStripeConfiguration?.apiKey;
      error.value = null;
    } catch (err) {
      error.value = err;
    } finally {
      loading.value = false;
    }
    // @ts-ignore
    return result;
  };

  return {
    load,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useStripePublicKey;
