export const giftGuideRequest = `
  query {
    categoryList(filters: { url_key: { in: ["home-gifts"] } }) {
      name
      children {
        image
        name
        url_path
        url_key
        url_suffix
      }
    }
  }
`;

