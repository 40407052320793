<template>
  <div class="sf-tabs__tab">
    <slot name="title" v-bind="{ tabClick, isActive, title }">
      <SfButton
        :aria-pressed="isActive.toString()"
        class="sf-button--pure sf-button--no-shadow sf-tabs__title"
        :class="{ 'is-active': isActive, 'desktop-only': hideSingleTitleOnMobile }"
        @click="tabClick"
      >
        {{ title }}
        <SfChevron
          class="sf-tabs__chevron"
          :class="{ 'sf-chevron--top': isActive }"
        />
      </SfButton>
    </slot>
    <div class="sf-tabs__content">
      <div :class="{ 'display-none': !isActive }" class="sf-tabs__content__tab">
        <SfScrollable
          v-if="tabMaxContentHeight"
          :max-content-height="tabMaxContentHeight"
          :show-text="tabShowText"
          :hide-text="tabHideText"
        >
          <slot />
        </SfScrollable>
        <slot v-else />
      </div>
    </div>
  </div>
</template>
<script>
import { isClient } from '~/utilities/helpers';
import { SfButton, SfChevron, SfScrollable } from '~/components';

export default {
  name: 'SfTab',
  components: {
    SfChevron,
    SfScrollable,
    SfButton,
  },
  inject: ['tabConfig'],
  props: {
    title: {
      type: String,
      default: '',
    },
    hideSingleTitleOnMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    tabMaxContentHeight() {
      return this.tabConfig.tabMaxContentHeight;
    },
    tabShowText() {
      return this.tabConfig.tabShowText;
    },
    tabHideText() {
      return this.tabConfig.tabHideText;
    },
    desktopMin() {
      return this.tabConfig.desktopMin;
    },
  },
  methods: {
    tabClick() {
      if(!isClient) return;
      const width = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth
      );
      if(this.isActive && width > this.desktopMin) return;
      this.$parent.$emit('toggle', this._uid);
    },
  },
};
</script>
