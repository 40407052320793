





import { useRoute, defineComponent } from '@nuxtjs/composition-api';
import IconSprite from '~/components/General/IconSprite.vue';

export default defineComponent({
  name: 'EmptyLayout',

  components: {
    IconSprite,
  },

  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
  head: {},
});
