<template functional>
  <div
    :class="[data.class, data.staticClass, 'sf-property']"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot name="name" v-bind="{ props }">
      <span class="sf-property__name">
        {{ `${props.name}${props.colon ? ':' : ''}` }}
      </span>
    </slot>
    <slot name="value" v-bind="{ props }">
      <span class="sf-property__value">
        {{ props.value }}
      </span>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'SfProperty',
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    colon: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss">
@import "~@/styles/components/atoms/SfProperty";
</style>
