import { computed, ComputedRef, reactive } from '@nuxtjs/composition-api';
import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState';

const state = reactive<StateInterface>({
  isDesktopMenuOpen: false,
  isMobileMenuOpen: false,
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isLoginModalOpenFromCheckoutForgotPassword: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: false,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isProductSizeSidebarOpen: false,
  isNotifyMeSidebarOpen: false,
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): {
  isCartSidebarOpen: ComputedRef<boolean>;
  toggleCartSidebar: () => void;

  isNewsletterModalOpen: ComputedRef<boolean>;
  toggleNewsletterModal: () => void;

  isDesktopMenuOpen: ComputedRef<boolean>;
  toggleDesktopMenu: () => void;

  isMobileMenuOpen: ComputedRef<boolean>;
  toggleMobileMenu: () => void;

  isWishlistSidebarOpen: ComputedRef<boolean>;
  toggleWishlistSidebar: () => void;

  isCategoryGridView: ComputedRef<boolean>;
  changeToCategoryListView: () => void;
  changeToCategoryGridView: () => void;

  isWishlistGridView: ComputedRef<boolean>;
  changeToWishlistGridView: () => void;
  changeToWishlistListView: () => void;

  isLoginModalOpen: ComputedRef<boolean>;
  toggleLoginModal: () => void;

  isLoginModalOpenFromCheckoutForgotPassword: ComputedRef<boolean>;
  toggleLoginModalFromCheckoutForgotPassword: () => void;

  isFilterSidebarOpen: ComputedRef<boolean>
  toggleFilterSidebar: () => void;

  isProductSizeSidebarOpen: ComputedRef<boolean>;
  toggleProductSizeSidebar: () => void;

  isNotifyMeSidebarOpen: ComputedRef<boolean>;
  toggleNotifyMeSidebar: () => void;
} {
  const toggleDesktopMenu = () => {
    state.isDesktopMenuOpen = !state.isDesktopMenuOpen;
  };

  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
    state.isLoginModalOpenFromCheckoutForgotPassword = false;
  };

  const toggleLoginModalFromCheckoutForgotPassword = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
    state.isLoginModalOpenFromCheckoutForgotPassword = !state.isLoginModalOpenFromCheckoutForgotPassword;
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };
  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };

  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  const toggleProductSizeSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isProductSizeSidebarOpen = !state.isProductSizeSidebarOpen;
  };

  const toggleNotifyMeSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isNotifyMeSidebarOpen = !state.isNotifyMeSidebarOpen;
  };

  return {
    isDesktopMenuOpen: computed(() => state.isDesktopMenuOpen),
    toggleDesktopMenu,

    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    toggleMobileMenu,

    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    toggleCartSidebar,

    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    toggleWishlistSidebar,

    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    toggleLoginModal,

    isLoginModalOpenFromCheckoutForgotPassword: computed(() => state.isLoginModalOpenFromCheckoutForgotPassword),
    toggleLoginModalFromCheckoutForgotPassword,

    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    toggleNewsletterModal,

    isCategoryGridView: computed(() => state.isCategoryGridView),
    changeToCategoryGridView,
    changeToCategoryListView,

    isWishlistGridView: computed(() => state.isWishlistGridView),
    changeToWishlistGridView,
    changeToWishlistListView,

    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    toggleFilterSidebar,

    isProductSizeSidebarOpen: computed(() => state.isProductSizeSidebarOpen),
    toggleProductSizeSidebar,

    isNotifyMeSidebarOpen: computed(() => state.isNotifyMeSidebarOpen),
    toggleNotifyMeSidebar,
  };
}

export default useUiState;
export * from './useUiState';
