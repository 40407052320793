<template>
  <div class="product-expand--wrapper">
    <slot />
  </div>
</template>
<script>
import { SfChevron, SfButton } from '~/components';
import { focus } from '~/utilities/directives';

export default {
  name: 'ProductInfo',
  directives: {focus},
  components: {
    SfChevron,
    SfButton,
  },
  props: {},
  provide() {
    return {
      items: this.items,
    };
  },
  data() {
    return {
      title: '',
      isOpen: [],
      items: [],
      isItemOpen: false,
      multiple: true,
    };
  },
  watch: {
    'isOpen': {
      handler(newVal) {
        this.isItemOpen = newVal.includes(this.title);
      },
    },
  },
  created() {
    this.items.push(this.title);
  },
  methods: {
    toggle(payload) {
      if(!this.multiple) {
        this.isOpen = [payload];
      } else if(this.isOpen.includes(payload)) {
        this.isOpen = this.isOpen.filter((item) => item !== payload);
      } else {
        this.isOpen.push(payload);
      }
      // this.$emit('change', this.isOpen);
    },
  },
  mounted() {
    this.toggle('Description');
  }
};
</script>
<style lang="scss">
</style>
