var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sf-gallery"},[_c('div',{staticClass:"sf-gallery__stage"},[_c('div',{ref:"glide",staticClass:"glide"},[_c('div',{staticClass:"glide__track",attrs:{"data-glide-el":"track"}},[_c('ul',{staticClass:"glide__slides"},_vm._l((_vm.images),function(picture,index){return _c('li',{key:'slide-' + index,staticClass:"glide__slide",on:{"mouseover":function($event){return _vm.startZoom(picture)},"mousemove":function($event){return _vm.moveZoom($event, index)},"mouseout":function($event){return _vm.removeZoom(index)}}},[_vm._t("big-image",function(){return [_c('SfImage',{ref:"sfGalleryBigImage",refInFor:true,staticClass:"sf-gallery__big-image",class:{ 'sf-gallery__big-image--has-zoom': _vm.enableZoom },attrs:{"src":picture.desktop.url,"alt":picture.alt,"placeholder":picture.placeholder,"width":_vm.imageWidth,"height":_vm.imageHeight,"image-tag":"img"},on:{"click":function($event){return _vm.$emit('click:stage', { picture: picture, index: index })}}})]},null,{
                enableZoom: _vm.enableZoom,
                picture: picture,
                index: index,
                imageWidth: _vm.imageWidth,
                imageHeight: _vm.imageHeight,
                imageTag: _vm.imageTag,
                nuxtImgConfig: _vm.nuxtImgConfig,
              })],2)}),0)])]),_vm._v(" "),_c('transition',{attrs:{"name":"sf-fade"}},[_c('div',{ref:"outSide",class:{
          'display-none':
            !_vm.outsideZoom || !_vm.isZoomStarted || (!_vm.outsideZoom && !_vm.enableZoom),
        },style:({ width: (_vm.imageWidth + "px"), height: (_vm.imageHeight + "px") })},[_vm._t("outside-zoom",function(){return [_c('SfImage',{ref:"imgZoom",staticClass:"sf-gallery__zoom",attrs:{"src":_vm.definedPicture.url,"width":_vm.imageWidth,"height":_vm.imageHeight,"lazy":false,"alt":_vm.definedPicture.alt,"placeholder":_vm.definedPicture.placeholder,"image-tag":"img"}})]},null,{
            definedPicture: _vm.definedPicture,
            imageWidth: _vm.imageWidth,
            imageHeight: _vm.imageHeight,
            imageTag: _vm.imageTag,
            nuxtImgConfig: _vm.nuxtImgConfig,
          })],2)])],1),_vm._v(" "),_c('div',{staticClass:"sf-gallery__thumbs"},[_vm._t("thumbs",function(){return _vm._l((_vm.images),function(image,index){return _c('SfButton',{key:'img-' + index,staticClass:"sf-button--pure sf-gallery__item",class:{ 'sf-gallery__item--selected': index === _vm.activeIndex },attrs:{"aria-label":'Image ' + index},on:{"click":function($event){return _vm.go(index)}}},[_c('SfImage',{staticClass:"sf-gallery__thumb",attrs:{"src":image.mobile.url,"alt":image.alt,"placeholder":image.placeholder,"width":_vm.thumbWidth,"height":_vm.thumbHeight,"image-tag":_vm.thumbImageTag,"nuxt-img-config":_vm.thumbNuxtImgConfig}})],1)})},null,{ images: _vm.images, active: _vm.activeIndex, go: _vm.go })],2)])}
var staticRenderFns = []

export { render, staticRenderFns }