<template>
  <div>
    <div class="color-square" v-for="(color, index) in colors.slice(0, 7)" :key="index"
         :style="{ backgroundColor: color }">

    </div>
    <div v-if="colors.length > 7">
      <div v-if="showRemaining">
        <div class="color-square" v-for="(color, index) in colors.slice(7)" :key="index + 7"
             :style="{ backgroundColor: color }">

        </div>
      </div>
      <div class="cross-icon" @click="showRemaining = !showRemaining"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductColor',
  data() {
    return {
      colors: ['red', 'blue', 'green', 'yellow', 'orange', 'purple', 'pink', 'brown', 'gray', 'black'],
      showRemaining: false
    };
  }
};
</script>

<style>
.color-square {
  width: 68px;
  height: 68px;
  display: inline-block;
  margin-right: 10px;
}

.cross-icon {
  width: 68px;
  height: 68px;
  display: inline-block;
  cursor: pointer;
}
</style>