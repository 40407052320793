<template>
  <transition name="sf-fade">
    <div
      :class="[{ 'display-none': !visible }, typeClass]"
      class="sf-notification"
      v-e2e="'notification'"
    >
      <slot name="icon" v-bind="{ icon }">
        <SfIcon
          :class="{ 'display-none': !icon }"
          class="sf-notification__icon"
          :icon="icon"
          size="xxl"
        />
      </slot>
      <div>
        <slot name="title" v-bind="{ title }">
          <div
            :class="{ 'display-none': !title }"
            class="sf-notification__title"
          >
            {{ title }}
          </div>
        </slot>
        <slot name="message" v-bind="{ message }">
          <span
            :class="{ 'display-none': !message }"
            class="sf-notification__message"
          >{{ message }}</span
          >
        </slot>
        <slot name="action" v-bind="{ action, actionHandler }">
          <SfButton
            :class="{ 'display-none': !action }"
            class="sf-button--pure sf-notification__action"
            @click="actionHandler"
          >
            {{ action }}
          </SfButton>
        </slot>
      </div>
      <slot name="close" v-bind="{ closeHandler }">
        <SfButton
          :class="{ 'display-none': persistent }"
          aria-label="Close notification"
          class="sf-button--pure sf-notification__close"
          @click="closeHandler"
        >
          <SfIcon
            class="sf-notification__close-icon"
            icon="cross"
            size="xxs"
          />
        </SfButton>
      </slot>
    </div>
  </transition>
</template>
<script>
import {
  SfIcon,
  SfButton
} from '~/components';

export default {
  name: 'SfNotification',
  components: {
    SfIcon,
    SfButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'info',
      validator: function (value) {
        return ['info', 'success', 'warning', 'danger'].includes(
          value
        );
      },
    },
  },
  computed: {
    icon() {
      switch (this.type) {
        case 'success':
          return 'info_circle';
        case 'info':
          return 'info_circle';
        case 'warning':
          return 'info_circle';
        case 'danger':
          return 'info_shield';
        default:
          return 'info_circle';
      }
    },
    typeClass() {
      switch (this.type) {
        case 'info':
          return 'type-info';
        case 'success':
          return 'type-success';
        case 'warning':
          return 'type-warning';
        case 'danger':
          return 'type-danger';
        default:
          return 'type-info';
      }
    },
  },
  methods: {
    actionHandler() {
      this.$emit('click:action');
    },
    closeHandler() {
      this.$emit('click:close');
    },
  },
};
</script>
<style lang="scss">
@import "~@/styles/components/molecules/SfNotification.scss";
</style>
