<template functional>
  <span
      :class="[data.class, data.staticClass, 'sf-chevron']"
      :style="[data.style, data.staticStyle]"
      v-bind="data.attrs"
      v-on="listeners"
  >
  </span>
</template>
<script>
import { focus } from '~/utilities/directives'

export default {
  name: 'SfChevron',
  directives: {
    focus,
  },
}
</script>
<style lang="scss">
@import "~@/styles/components/atoms/SfChevron.scss";
</style>
