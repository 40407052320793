var render = function (_h,_vm) {var _c=_vm._c;return _c(_vm.props.link ? _vm.injections.components.SfLink : 'button',_vm._g(_vm._b({tag:"component",class:[
      _vm.data.class,
      _vm.data.staticClass,
      'sf-button',
      {
        'is-disabled--button': _vm.$options.buttonActive(
          _vm.props.link,
          _vm.props.disabled
        ),
        'is-disabled--link': _vm.$options.linkActive(_vm.props.link, _vm.props.disabled),
      } ],style:([_vm.data.style, _vm.data.staticStyle]),attrs:{"aria-disabled":_vm.props.disabled,"link":_vm.props.link,"type":_vm.props.type}},'component',_vm.data.attrs,false),!_vm.props.disabled ? _vm.listeners : {}),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }