<template>
  <div class="sf-bottom-modal">
    <SfOverlay
      :visible="isOpen"
      class="sf-bottom-modal__overlay"
      @click="close"
    />
    <SfTransition :transition="transition">
      <div
        v-show="isOpen"
        role="dialog"
        aria-modal="true"
        class="sf-bottom-modal__container"
      >
        <slot v-if="isTopBar" name="top-bar">
          <div class="sf-bottom-modal__topbar desktop-only">
            <SfIcon
              icon="cross"
              size="1.5rem"
              @click="close"
            />
          </div>
        </slot>
        <slot name="title">
          <SfHeading
            :class="{ 'display-none': !title }"
            :level="3"
            :title="title"
            class="sf-bottom-modal__title"
          />
        </slot>
        <slot v-if="!isTopBar" name="close-desktop">
          <SfIcon
            class="sf-bottom-modal__close desktop-only"
            icon="M13.85 13.168c.148.147.15.303.03.423l-.318.318c-.13.13-.253.106-.362-.003L6.994 7.7.832 13.857c-.147.147-.303.15-.423.03l-.318-.318c-.13-.13-.106-.253.003-.362l6.204-6.204-6.2-6.2C.002.707-.052.585.07.463l.382-.38c.11-.111.206-.117.345.022l6.197 6.202L13.197.105c.096-.097.218-.15.34-.028l.38.382c.111.11.117.206-.022.344l-6.204 6.2 6.16 6.165Z"
            size="sm"
            color="black"
            viewBox="0 0 24 24"
            :coverage="1"
            @click="close"
          />
        </slot>
        <slot />
        <slot name="close-mobile">
          <SfButton
            class="sf-button--full-width sf-bottom-modal__cancel"
            aria-label="Close"
            @click="close"
          >Cancel
          </SfButton
          >
        </slot>
      </div>
    </SfTransition>
  </div>
</template>
<script>
import {
  SfIcon,
  SfOverlay,
  SfButton,
  SfHeading
} from '~/components';

import SfTransition from '~/utilities/transitions/component/SfTransition';
import { isClient } from '~/utilities/helpers';

export default {
  name: 'SfBottomModal',
  components: {
    SfOverlay,
    SfButton,
    SfIcon,
    SfHeading,
    SfTransition
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isTopBar: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    transition: {
      type: [String, Boolean],
      default: '',
    },
  },
  watch: {
    isOpen: {
      handler(value) {
        if(!isClient) return;
        if(value) {
          document.addEventListener('keydown', this.keydownHandler);
        } else {
          document.removeEventListener('keydown', this.keydownHandler);
        }
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.$emit('click:close');
    },
    keydownHandler(e) {
      if(e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        this.close();
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@/styles/components/molecules/SfBottomModal.scss";
</style>
