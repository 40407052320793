






























































































































import {
  SfButton,
  SfImage,
  SfInput,
  SfLoader,
  SfTextarea
} from '~/components';

import {
  defineComponent,
  ref,
  computed,
  useContext
} from '@nuxtjs/composition-api';

import {
  useUiNotification,
  useWebsiteCode
} from '~/composables';

import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  extend
} from 'vee-validate';

import {
  email,
  min,
  required
} from 'vee-validate/dist/rules';

import {
  customerPhoneRegExp,
  invalidPhoneMsg
} from '~/helpers/phoneValidation';

setInteractionMode('eager');
extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('email', {
  ...email,
  message: 'Enter a correct email',
});
extend('phone', {
  message: invalidPhoneMsg,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  validate: (value) => customerPhoneRegExp.test(value),
});


export default defineComponent({
  name: 'ContactUsForm',
  components: {
    SfButton,
    SfImage,
    SfInput,
    SfLoader,
    SfTextarea,
    ValidationObserver,
    ValidationProvider,
  },
  head() {
    return {
      title: this.$i18n.t( this.isContactUsPage ? 'Contact Us' : this.isBoutiquePage ? 'Boutique' : '') as string
    };
  },
  props: {
    identifier: {
      type: String,
      require: true
    },
    countryId: {
      type: String,
      default: '',
    }
  },
  setup(props) {
    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { app: { i18n }, $config, $recaptcha, app: application } = useContext();

    const { send: sendNotification } = useUiNotification();

    const { isAu } = useWebsiteCode();

    const isContactUsPage = computed( () => Boolean(props.identifier === 'contact_us'));

    const isBoutiquePage = computed( () => Boolean(props.identifier === 'boutique'));

    const computedIdentifier = computed(() => {
      if (isContactUsPage.value) {
        return props.identifier;
      }

      if (isBoutiquePage.value) {
        return `${props.identifier}_${props.countryId.toLowerCase()}`;
      }

      return props.identifier;
    });

    const isRecaptchaEnabled = ref(typeof $recaptcha !== 'undefined' && $config.isRecaptcha);
    let recaptchaToken = '';

    type Form = {
      name: string,
      email: string,
      telephone: string,
      comment: string,
      recaptchaToken?: string,
      recaptchaInstance?: string,
    };

    const form = ref<Form>({
      name: '',
      email: '',
      telephone: '',
      comment: '',
    });

    const clearForm = () => {
      Object.keys(form.value).forEach(key => {
        form.value[key] = '';
      });
    };

    const loading = ref(false);

    const handleFormSubmit = (reset: () => void) => async () => {

      try {
        loading.value = true;
        if (isRecaptchaEnabled.value) {
          $recaptcha.init();
          recaptchaToken = await $recaptcha.getResponse();
        }
        const { data, errors } = await application.$vsf.$magento.api.submitContactUsForm({
          identifier: computedIdentifier.value,
          input: {
            comment: form.value.comment,
            email: form.value.email,
            telephone: form.value.telephone,
            full_name: form.value.name,
          }
        });

        clearForm();
        reset();

        if (data.contactUs && data.contactUs.message) {
          sendNotification({
            id: Symbol('contact-us_success'),
            message: i18n.t('Contact us success message') as string,
            type: 'success',
            icon: 'check',
            persist: false,
            title: i18n.t('Email Sent!') as string,
          });
        }
        if (errors && errors.length && errors[0].message) {
          sendNotification({
            id: Symbol('contact-us_error'),
            message: i18n.t('Something went wrong. Try it later.') as string,
            type: 'danger',
            icon: 'info_shield',
            persist: false,
            title: i18n.t('Error') as string,
          });
          console.error('Contact us request error: ', errors);
        }
        if (isRecaptchaEnabled.value) $recaptcha.reset();
      } catch (error) {
        console.error('Error: ', error);
      } finally {
        loading.value = false;
        if (isRecaptchaEnabled.value) {
          $recaptcha.reset();
        }
      }
    };

    return {
      loading,
      form,
      isRecaptchaEnabled,
      isAu,
      isContactUsPage,
      isBoutiquePage,
      handleFormSubmit
    };
  },
});
