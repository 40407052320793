










































































































































































import { SfButton, SfBadge, SfIcon, SfLink, SfOverlay } from '~/components';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import {
  computed,
  ref,
  defineComponent,
  useContext,
  onMounted,
  useFetch,
} from '@nuxtjs/composition-api';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import {
  useUiHelpers,
  useUiState, useWebsiteCode,
} from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import userGetters from '~/modules/customer/getters/userGetters';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import Header from '~/components/Header/Header.vue';
import { useTopBar } from './TopBar/useTopBar';

export default defineComponent({
  components: {
    HeaderNavigation,
    Header,
    SfOverlay,
    SfButton,
    SfBadge,
    SfIcon,
    SfLink,
    CurrencySelector: () => import('~/components/CurrencySelector.vue'),
    StoreSwitcher: () => import('~/components/StoreSwitcher.vue'),
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchResults: () => import(
      /* webpackPrefetch: true */ '~/components/Header/SearchBar/SearchResults.vue'
      ),
  },
  setup(_, { refs }) {
    const { app, route } = useContext();
    const {
      toggleCartSidebar,
      toggleMobileMenu,
      toggleDesktopMenu,
      isDesktopMenuOpen,
      isMobileMenuOpen
    } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { user, load: loadUser } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { categories: categoryList, load: categoriesListLoad } = useCategory();
    const { isMe } = useWebsiteCode();
    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    const isSticky = ref(true);
    const isHovered = ref(false);

    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);
    const categorySearchResults = ref(null);
    const searchTerm = ref(null);
    const searchTotalResults = ref(0);

    const categoryTree = ref<CategoryTree[]>([]);

    useFetch(async () => {
      if (user.value === null) {
        await loadUser();
      }
    });

    const userFirstName = computed(() => user.value
      ? user.value.firstname
      : userGetters.getFirstName(user.value));

    useFetch(async () => {
      await categoriesListLoad({ pageSize: 99 });
      categoryTree.value = categoryList.value?.[0]?.children || [];
    });

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
      }

      const observer = new IntersectionObserver(() => isSticky.value = !isSticky.value,{ threshold: [1] });
      observer.observe(refs.headerTopWrapper as Element);
    });

    const needTransparentHeader = computed(() => (
      ['home', 'sleep-system', 'yacht', 'couture', 'bedding-in-dubai'].some(term => route.value.name.includes(term))
    ));

    const isHeaderTransparent = computed(() => (
      needTransparentHeader.value &&
        !isSticky.value &&
        !isDesktopMenuOpen.value &&
        !isMobileMenuOpen.value &&
        !isSearchOpen.value &&
        !isHovered.value
    ));

    const clearSearch = () => {
      isSearchOpen.value = false;
      searchTerm.value = null;
      productSearchResults.value = null;
      categorySearchResults.value = null;
      if (document) document.body.classList.remove('no-scroll');
      setTimeout(() => isHovered.value = false, 10);
    };


    const isCustomMadePage = computed(() => route.value.path.includes('/couture'));

    return {
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      isAuthenticated,
      isHovered,
      isHeaderTransparent,
      isSearchOpen,
      isDesktopMenuOpen,
      isMobileMenuOpen,
      productSearchResults,
      categorySearchResults,
      searchTerm,
      searchTotalResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleMobileMenu,
      toggleDesktopMenu,
      hasCurrencyToSelect,
      hasStoresToSelect,
      userFirstName,
      clearSearch,
      isMe,
      isCustomMadePage,
    };
  },
});
