import { ref, useContext } from '@nuxtjs/composition-api';

export function usePromo() {
  const { app } = useContext();
  const loadingPromos = ref(false);
  const loadingPromo = ref(false);

  const loadPromos = async (params = {}) => {
    let result = ref();

    try {
      loadingPromos.value = true;
      // @ts-ignore
      result.value = await app.$vsf.$magento.api.getPromos();
    } catch (error) {
      console.warn('Error: ', error);
    } finally {
      loadingPromos.value = false;
    }

    return result;
  };

  const loadPromo = async (promotionId) => {
    let result = ref();

    try {
      loadingPromo.value = true;
      // @ts-ignore
      result.value = await app.$vsf.$magento.api.getPromo({ promotionId: promotionId });
    } catch (error) {
      console.warn('Error: ', error);
    } finally {
      loadingPromo.value = false;
    }

    return result;
  };

  return {
    loadPromos,
    loadPromo,
    loadingPromos,
    loadingPromo,
  };
}

export default usePromo;
