import type { Plugin } from '@nuxt/types';
import type { ApolloQueryResult } from '@apollo/client/core/types';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export const hasGraphqlAuthorizationError = (res: ApolloQueryResult<unknown>) => res?.errors
  ?.some((error) => error?.extensions?.category === 'graphql-authorization') ?? false;


const plugin : Plugin = ({ $pinia, app }) => {
  const customerStore = useCustomerStore($pinia);
  if (app.$vsf.$magento.config.state.getCustomerToken()) {
    customerStore.setIsLoggedIn(true);
  }

  app.$vsf.$magento.client.interceptors.response.use(async (res) => {
    if (!hasGraphqlAuthorizationError(res.data as ApolloQueryResult<unknown>)) {
      return res;
    } else {
      customerStore.setIsLoggedIn(false);
      app.$vsf.$magento.config.state.removeCustomerToken();
      // @ts-ignore
      app.$vsf.$magento.config.state.removeRefreshToken();
      app.$vsf.$magento.config.state.removeCartId();

      app.router.push(app.localePath('/'));
    }
  });
};

export default plugin;
