<template>
  <div class="sf-collected-product">
    <slot name="title" v-bind="{ title }">
      <div class="sf-collected-product__top-title-wrapper">
        <component
          :is="componentIs"
          class="sf-collected-product__title"
          :link="link ? link : ''"
        >
          {{ title }}
        </component>
      </div>
    </slot>
    <div class="sf-collected-product__main-wrapper">
      <div class="sf-collected-product__aside">
        <slot name="image" v-bind="{ image, title }">
          <SfImage
            :src="image"
            :alt="title"
            :width="imageWidth"
            :height="imageHeight"
            class="sf-collected-product__image"
          />
        </slot>
      </div>
      <div class="sf-collected-product__main">
        <div class="sf-collected-product__details">
          <slot name="title" v-bind="{ title }">
            <div class="sf-collected-product__title-wrapper desktop-only">
              <component
                :is="componentIs"
                class="sf-collected-product__title"
                :link="link ? link : ''"
              >
                {{ title }}
              </component>
            </div>
          </slot>
          <slot name="price" v-bind="{ specialPrice, regularPrice }">
            <SfPrice
              :class="{ 'display-none': !regularPrice }"
              :regular="regularPrice"
              :special="specialPrice"
            />
          </slot>
          <div class="sf-collected-product__configuration">
            <slot name="configuration">
              <SfProperty name="Size" value="XS" />
              <SfProperty name="Color" value="white" />
            </slot>
          </div>
          <slot name="input">
            <div class="sf-collected-product__quantity-wrapper">
              <SfQuantitySelector
                :qty="quantity"
                :min="minQty"
                :max="maxQty"
                class="sf-collected-product__quantity-selector"
                @input="$emit('input', $event)"
              />
            </div>
          </slot>
          <div class="sf-collected-product__actions-wrapper desktop-only">
            <div class="sf-collected-product__actions-row">
              <slot name="remove" v-bind="{ removeHandler }">
                <div :class="{ 'display-none': !hasRemove }">
                  <SfButton
                    aria-label="Remove"
                    class="sf-collected-product__remove"
                    data-testid="collected-product-desktop-remove"
                    @click="removeHandler"
                  >
                    {{ $t('Remove from Cart') }}
                  </SfButton>
                </div>
              </slot>
              <slot name="add-to-wishlist">
                <div>
                  <SfButton
                    aria-label="Wishlist toggle"
                    class="sf-collected-product__remove"
                    data-testid="collected-product-desktop-add-to-wishlist"
                    @click="addToWishlist"
                  >
                    {{ isInWishlist ? $t('Remove from Wishlist') : $t('Add to Wishlist') }}
                  </SfButton>
                </div>
              </slot>
            </div>
            <slot name="more-actions" v-bind="{ actionsHandler }">
              <div :class="{ 'display-none': !hasMoreActions }">
                <SfButton
                  aria-label="More actions"
                  class="
                    sf-button--pure
                    sf-collected-product__more-actions
                    mobile-only
                  "
                  @click="actionsHandler"
                >
                  <SfIcon icon="more" size="18px" />
                </SfButton>
              </div>
            </slot>
            <div class="sf-collected-product__actions">
              <slot name="actions">
                <SfButton class="sf-button--text desktop-only"
                >Save for later
                </SfButton
                >
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sf-collected-product__bottom-actions-wrapper">
      <div class="sf-collected-product__actions-row">
        <slot name="remove" v-bind="{ removeHandler }">
          <div :class="{ 'display-none': !hasRemove }">
            <SfButton
              aria-label="Remove"
              class="sf-collected-product__remove"
              data-testid="collected-product-desktop-remove"
              @click="removeHandler"
            >
              {{ $t('Remove from Cart') }}
            </SfButton>
          </div>
        </slot>
        <slot name="add-to-wishlist">
          <div>
            <SfButton
              aria-label="Wishlist toggle"
              class="sf-collected-product__remove"
              data-testid="collected-product-desktop-add-to-wishlist"
              @click="addToWishlist"
            >
              {{ isInWishlist ? $t('Remove from Wishlist') : $t('Add to Wishlist') }}
            </SfButton>
          </div>
        </slot>
      </div>
      <slot name="more-actions" v-bind="{ actionsHandler }">
        <div :class="{ 'display-none': !hasMoreActions }">
          <SfButton
            aria-label="More actions"
            class="
            sf-button--pure
            sf-collected-product__more-actions
            mobile-only
          "
            @click="actionsHandler"
          >
            <SfIcon icon="more" size="18px" />
          </SfButton>
        </div>
      </slot>
      <div class="sf-collected-product__actions">
        <slot name="actions">
          <SfButton class="sf-button--text desktop-only"
          >Save for later
          </SfButton
          >
        </slot>
      </div>
    </div>

  </div>
</template>
<script>
import { SfPrice, SfIcon, SfCircleIcon, SfImage, SfButton, SfLink, SfProperty, SfQuantitySelector } from '~/components';

export default {
  name: 'SfCollectedProduct',
  components: {
    SfButton,
    SfIcon,
    SfImage,
    SfCircleIcon,
    SfPrice,
    SfQuantitySelector,
    SfLink,
    SfProperty,
  },
  model: {
    prop: 'qty',
  },
  props: {
    image: {
      type: String,
      default: '',
    },
    imageWidth: {
      type: [Number, String],
      default: 140,
    },
    imageHeight: {
      type: [Number, String],
      default: 140,
    },
    title: {
      type: String,
      default: '',
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    qty: {
      type: [Number, String],
      default: 1,
    },
    minQty: {
      type: Number,
      default: null,
    },
    maxQty: {
      type: Number,
      default: 99,
    },
    link: {
      type: [String, Object],
      default: null,
    },
    isInWishlist: {
      type: Boolean,
      default: false
    },
    hasRemove: {
      type: Boolean,
      default: true,
    },
    hasMoreActions: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    componentIs() {
      return this.link ? 'SfLink' : 'div';
    },
    quantity() {
      return typeof this.qty === 'string' ? Number(this.qty) : this.qty;
    },
  },
  methods: {
    removeHandler() {
      this.$emit('click:remove');
    },
    addToWishlist() {
      this.$emit('click:addToWishlist');
    },
    actionsHandler() {
      this.$emit('click:actions');
    },
  },
};
</script>
<style lang="scss">
@import "~@/styles/components/organisms/SfCollectedProduct.scss";
</style>
