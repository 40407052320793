var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"sf-pagination"},[_vm._t("prev",function(){return [_c('div',{staticClass:"sf-pagination__item prev",class:{ 'display-none': !_vm.canGoPrev }},[_c(_vm.componentIs,{tag:"component",staticClass:"sf-pagination__item-button",class:{
          'sf-button--pure': !_vm.hasRouter,
          'sf-arrow--transparent': !_vm.hasRouter && !_vm.canGoPrev,
        },attrs:{"link":_vm.hasRouter ? _vm.getLinkTo(_vm.getPrev) : null,"disabled":!_vm.hasRouter && !_vm.canGoPrev ? true : false,"aria-label":"Go to previous page","data-testid":"pagination-button-prev"},on:{"click":function($event){_vm.hasRouter ? null : _vm.go(_vm.getPrev)}}},[_c('SfIcon',{attrs:{"icon":"chevron_left","size":"16px"}})],1)],1)]},null,{ isDisabled: !_vm.canGoPrev, go: _vm.go, prev: _vm.getPrev }),_vm._v(" "),[_vm._t("number",function(){return [_c(_vm.componentIs,{tag:"component",staticClass:"sf-pagination__item",class:{
          'sf-button--pure': !_vm.hasRouter,
          'display-none': !_vm.showFirst,
        },attrs:{"link":_vm.hasRouter ? _vm.getLinkTo(1) : null},on:{"click":function($event){_vm.hasRouter ? null : _vm.go(1)}}},[_vm._v("\n        1\n      ")])]},null,{ page: 1 }),_vm._v(" "),_vm._t("points",function(){return [_c('div',{staticClass:"sf-pagination__item",class:{ 'display-none': _vm.firstVisiblePageNumber <= 2 }},[_vm._v("\n        ...\n      ")])]})],_vm._v(" "),_vm._l((_vm.limitedPageNumbers),function(page){return [_vm._t("number",function(){return [_c(_vm.currentPage === page ? 'span' : _vm.componentIs,{key:page,tag:"component",staticClass:"sf-pagination__item",class:{
          'sf-button--pure': !_vm.hasRouter && _vm.currentPage !== page,
          current: _vm.currentPage === page,
        },attrs:{"link":_vm.hasRouter && _vm.currentPage !== page ? _vm.getLinkTo(page) : null},on:{"click":function($event){!_vm.hasRouter && _vm.currentPage !== page ? _vm.go(page) : null}}},[_vm._v("\n        "+_vm._s(page)+"\n      ")])]},null,{ page: page, currentPage: _vm.currentPage })]}),_vm._v(" "),(_vm.showLast)?[_vm._t("points",function(){return [_c('div',{staticClass:"sf-pagination__item",class:{
          'display-none': _vm.lastVisiblePageNumber >= _vm.total - 1,
        }},[_vm._v("\n        ...\n      ")])]}),_vm._v(" "),_vm._t("number",function(){return [_c(_vm.componentIs,{tag:"component",staticClass:"sf-pagination__item",class:{
          'sf-button--pure': !_vm.hasRouter,
        },attrs:{"link":_vm.hasRouter ? _vm.getLinkTo(_vm.total) : null},on:{"click":function($event){_vm.hasRouter ? null : _vm.go(_vm.total)}}},[_vm._v("\n        "+_vm._s(_vm.total)+"\n      ")])]},null,{ page: _vm.total })]:_vm._e(),_vm._v(" "),_vm._t("next",function(){return [_c('div',{staticClass:"sf-pagination__item next",class:{ 'display-none': !_vm.canGoNext }},[_c(_vm.componentIs,{tag:"component",staticClass:"sf-pagination__item-button",class:{
          'sf-button--pure': !_vm.hasRouter,
          'sf-arrow--transparent': !_vm.hasRouter && !_vm.canGoNext,
        },attrs:{"link":_vm.hasRouter ? _vm.getLinkTo(_vm.getNext) : null,"disabled":!_vm.hasRouter && !_vm.canGoNext ? true : false,"aria-label":"Go to previous next","data-testid":"pagination-button-next"},on:{"click":function($event){_vm.hasRouter ? null : _vm.go(_vm.getNext)}}},[_c('SfIcon',{attrs:{"icon":"chevron_right","size":"14px"}})],1)],1)]},null,{ isDisabled: !_vm.canGoNext, go: _vm.go, next: _vm.getNext })],2)}
var staticRenderFns = []

export { render, staticRenderFns }