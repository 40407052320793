<template>
  <section class="sf-section">
    <slot
        name="heading"
        v-bind="{ levelHeading, titleHeading, subtitleHeading }"
    >
      <SfHeading
          v-if="titleHeading"
          :level="levelHeading"
          :title="titleHeading"
          :description="subtitleHeading"
      />
    </slot>
    <div class="sf-section__content">
      <slot />
    </div>
  </section>
</template>
<script>
import { SfHeading } from '~/components'

export default {
  name: 'SfSection',
  components: {
    SfHeading,
  },
  props: {
    titleHeading: {
      type: String,
      default: '',
    },
    subtitleHeading: {
      type: String,
      default: '',
    },
    levelHeading: {
      type: Number,
      default: 2,
    },
  },
}
</script>
<style lang="scss">
@import "~@/styles/components/molecules/SfSection.scss";
</style>
