<template>
  <div class="sf-footer-column col-12 col-m-3">
    <SfButton
      v-focus
      type="button"
      class="sf-button--pure sf-button--no-shadow sf-footer-column__title"
      @click="toggle(title)"
    >
      {{ title }}
      <span class="sf-footer-column__chevron mobile-only">
        <SfChevron
          class="sf-chevron--white"
          :class="{ 'sf-chevron--top': isColumnOpen }"
        />
      </span>
    </SfButton>
    <transition name="sf-fade">
      <div
        :class="{
          'sf-footer-column__content--hidden': !isColumnOpen,
        }"
        class="sf-footer-column__content"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>
<script>
import { SfChevron, SfButton } from '~/components';
import { focus } from '~/utilities/directives';

export default {
  name: 'SfFooterColumn',
  directives: {focus},
  components: {
    SfChevron,
    SfButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  inject: ['items'],
  data() {
    return {
      isColumnOpen: false,
    };
  },
  watch: {
    '$parent.isOpen': {
      handler(newVal) {
        this.isColumnOpen = newVal.includes(this.title);
      },
    },
  },
  created() {
    this.items.push(this.title);
  },
  methods: {
    toggle(payload) {
      this.$parent.toggle(payload);
    },
  },
};
</script>
