<template>
  <footer class="sf-footer">
    <div class="sf-footer__container grid">
      <slot />
    </div>
    <div class="sf-footer__store-switcher mobile-only">
      <StoreSwitcher />
      <CurrencySelector
        v-if="hasCurrencyToSelect"
      />
    </div>
    <SocialLinks class="" />
    <div class="sf-footer__copyright">
      <div class="sf-footer__label">© {{ currentYear }} Togas.</div>
      <span class="sf-footer__right">All Rights Reserved. </span>
      <SfLink :link="localePath('/sitemap')" class="sf-footer__copyright-link">Sitemap</SfLink>
    </div>
  </footer>
</template>
<script>
import Vue from 'vue';
import SfFooterColumn from './_internal/SfFooterColumn.vue';
import { SfLink } from '~/components';
import { useTopBar } from '~/components/TopBar/useTopBar';

import SocialLinks from '~/components/togas/SocialLinks.vue';

Vue.component('SfFooterColumn', SfFooterColumn);
export default {
  name: 'SfFooter',
  components: {
    SfLink,
    SocialLinks,
    StoreSwitcher: () => import('~/components/StoreSwitcher.vue'),
    CurrencySelector: () => import('~/components/CurrencySelector.vue'),
  },
  setup() {
    const {hasCurrencyToSelect, hasStoresToSelect} = useTopBar();

    return {
      hasCurrencyToSelect,
      hasStoresToSelect
    };
  },
  props: {
    column: {
      type: Number,
      default: 4,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    open: {
      type: [String, Array],
      default: () => [],
    },
  },
  provide() {
    return {
      items: this.items,
    };
  },
  data() {
    return {
      isOpen: [],
      items: [],
    };
  },
  methods: {
    toggle(payload) {
      if(!this.multiple) {
        this.isOpen = [payload];
      } else if(this.isOpen.includes(payload)) {
        this.isOpen = this.isOpen.filter((item) => item !== payload);
      } else {
        this.isOpen.push(payload);
      }
      this.$emit('change', this.isOpen);
    },
  },
  computed: {
    currentYear() {
      const date = new Date();
      return date.getFullYear();
    },
  }
};
</script>
<style lang="scss">
@import "~@/styles/components/organisms/SfFooter.scss";
</style>
