<template>
  <div class="sf-bar">
    <div>
      <slot name="title" v-bind="{ title, isTopLevel }">
        <div class="sf-bar__title" v-if="!isTopLevel">{{ title }}</div>
      </slot>
    </div>
    <div v-if="backToHomePage">
      <slot name="backToHomePage">
        <SfButton
          :class="{ 'display-none': !backToHomePage }"
          aria-label="back"
          class="sf-button--pure sf-bar__icon"
          type="button"
          @click="$emit('click:backToHomePage')"
        >
          <SfIcon icon="cross" size="0.875rem" />
        </SfButton>
      </slot>
    </div>
    <div v-else>
      <slot name="back">
        <SfButton
          :class="{ 'display-none': !back }"
          aria-label="back"
          class="sf-button--pure sf-bar__icon"
          type="button"
          @click="$emit('click:back')"
        >
          <SfIcon color="black" icon="cross" size="1.5rem" />
        </SfButton>
      </slot>
    </div>
    <div v-if="close">
      <slot name="close">
        <SfButton
          class="sf-button--pure sf-bar__icon"
          aria-label="close"
          type="button"
          @click="$emit('click:close')"
        >
          <SfIcon icon="cross" size="24px" />
        </SfButton>
      </slot>
    </div>
  </div>
</template>
<script>
import { SfButton, SfIcon } from '~/components';

export default {
  name: 'SfBar',
  components: {
    SfIcon,
    SfButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isTopLevel: {
      type: Boolean,
      default: true,
    },
    back: {
      type: Boolean,
      default: false,
    },
    backToHomePage: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
@import "~@/styles/components/molecules/SfBar.scss";
</style>
