/* eslint-disable  */

export default {
  'Togas': 'Togas',
  'About us': 'About us',
  'Account': 'Account',
  'Add new address': 'Add new address',
  'Add to compare': 'Add to compare',
  'Add to Wishlist': 'Add to Wishlist',
  'Remove from Wishlist': 'Remove from Wishlist',
  'Additional Information': 'Additional Information',
  'All Orders': 'All Orders',
  'Allow order notifications': 'Allow order notifications',
  'Apply': 'Apply',
  'Applied Coupon': 'Applied Coupon',
  'Attention!': 'Attention!',
  'Back to home': 'Back to home',
  'Back to homepage': 'Back to homepage',
  'Billing': 'Billing',
  'Billing address': 'Billing address',
  'Billing details': 'Billing details',
  'Brand': 'Brand',
  'Cancel': 'Cancel',
  'Cart': 'Cart',
  'Categories': 'Categories',
  'Change': 'Change',
  'Checkout': 'Checkout',
  'Change password your account': 'If you want to change the password to access your account, enter the following information',
  'Clear all': 'Clear all',
  'Color': 'Color',
  'Commercial information': 'and agree to receive personalized commercial information from Brand name by email',
  'Contact details updated': 'Keep your addresses and contact details updated.',
  'Contact us': 'Contact us',
  'Continue to billing': 'Continue to billing',
  'Continue to payment': 'Continue to payment',
  'Continue to shipping': 'Continue to shipping',
  'Continue to Shipping': 'Continue to Shipping',
  'Cookies Policy': 'Cookies Policy',
  'Create an account': 'Create an account',
  'Customer Reviews': 'Customer Reviews',
  'Customer service': 'Customer service',
  'Date': 'Date',
  'Default Billing Address': 'Default Billing Address',
  'Default Shipping Address': 'Default Shipping Address',
  'Update the address': 'Update the address',
  'Delete': 'Delete',
  'Departments': 'Departments',
  'Description': 'Description',
  'Details and status orders': 'Check the details and status of your orders in the online store. You can also cancel your order or request a return.',
  'Discount': 'Discount',
  'Promo code': 'Promo code',
  'You saved': 'You saved',
  'Done': 'Done',
  'Download': 'Download',
  'Download all': 'Download all',
  'Edit': 'Edit',
  'E-mail': 'E-mail',
  'Email Sent!': 'Email Sent!',
  'Email address': 'Email address',
  'Empty': 'Tap any heart next to a product to favorite. We’ll save them for you here!',
  'Empty wishlist': 'Empty wishlist',
  'Enjoy your free account': 'Enjoy these perks with your free account!',
  'Enter promo code': 'Enter promo code',
  'Feedback': 'Your feedback is important to us. Let us know what we could improve.',
  'Filters': 'Filters',
  'All filters': 'All filters',
  'Find out more': 'Find out more',
  'First Name': 'First Name',
  'Forgot Password': 'Enter your email address to receive the password reset information.',
  'You password reset link is invalid': 'You password reset link is invalid',
  'Set Password': 'Enter a new password and return to log in',
  'Repeat Password': 'Repeat Password',
  'Save Changes': 'Save Changes',
  'Forgot Password Modal Email': 'Email you are using to sign in:',
  'forgotPasswordConfirmation': 'Thanks! If there is an account registered with the {0} email, you will find message with a password reset link in your inbox.',
  'Forgot password': 'Forgot password',
  'Request': 'Request',
  'Request to reset your password': 'Request to reset your password',
  'Request to reset your password received': 'Request to reset your password received',
  'Go to Homepage': 'Go to Homepage',
  'Go back': 'Go back',
  'Go back shopping': 'Go back shopping',
  'Go back to shop': 'Go back to shop',
  'Go to checkout': 'Go to checkout',
  'Go to cart': 'Go to cart',
  'Get Back to Shopping': 'Get Back to Shopping',
  'Guarantee': 'Guarantee',
  'Help': 'Help',
  'Help & FAQs': 'Help & FAQs',
  'hide': 'hide',
  'Home': 'Home',
  'I agree to': 'I agree to',
  'I confirm subscription': 'I confirm subscription',
  'I want to create an account': 'I want to create an account',
  'Info after order': 'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, check history of transactions, edit subscription to newsletter.',
  'Instruction1': 'Take care of me',
  'Instruction2': 'Just here for the care instructions?',
  'Instruction3': 'Yeah, we thought so',
  'It was not possible to request a new password, please check the entered email address.': 'It was not possible to request a new password, please check the entered email address.',
  'Item': 'Item',
  'Items': 'Items',
  'Kidswear': 'Kidswear',
  'Last Name': 'Last Name',
  'Let’s start now – we’ll help you': 'Let’s start now – we’ll help you.',
  'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.': 'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.',
  'Log into your account': 'Log into your account',
  'Login': 'Login',
  'Log In': 'Log In',
  'Log in': 'Log in',
  'Login / Register': 'Login / Register',
  'or Log in to Your Account': 'or Log in to Your Account',
  'Looks like you haven\'t added any items to the bag yet. Start shopping to fill it in.': 'Looks like you haven\'t added any items to the bag yet. Start shopping to fill it in.',
  'Tap any heart next to a product to favorite. We\'ll save them for you here!': 'Tap any heart next to a product to favorite. We\'ll save them for you here!',
  'Make an order': 'Make an order',
  'Make an Order': 'Make an Order',
  'Manage addresses': 'Manage all the addresses you want (work place, home address...) This way you won\'t have to enter the address manually with each order.',
  'Manage billing addresses': 'Manage all the billing addresses you want (work place, home address...) This way you won\'t have to enter the billing address manually with each order.',
  'Manage shipping addresses': 'Manage all the shipping addresses you want (work place, home address...) This way you won\'t have to enter the shipping address manually with each order.',
  'Match it with': 'Match it with',
  'Men fashion': 'Men fashion',
  'Menu': 'Menu',
  'My billing and shipping address are the same': 'My billing and shipping address are the same',
  'My Cart': 'My Cart',
  'No account': 'Don\'t have an account yet?',
  'Number of items': 'Number of items',
  'or': 'or',
  'or fill the details below': 'or fill the details below',
  'Order ID': 'Order ID',
  'Order information': 'Order information',
  'Order No.': 'Order No.',
  'Order summary': 'Order summary',
  'Order Summary': 'Order summary',
  'Complete order': 'Complete order',
  'Delivery': 'Delivery',
  'Default Address': 'Default Address',
  'Other products you might like': 'Other products you might like',
  'Out of stock': 'Out of stock',
  'Password*': 'Password*',
  'Password Changed': 'Password successfuly changed. You can now go back to homepage and sign in.',
  'Pay for order': 'Pay for order',
  'Payment': 'Payment',
  'Payment & Delivery': 'Payment & Delivery',
  'Payment Method': 'Payment Method',
  'Payment Methods': 'Payment Methods',
  'Personal details': 'Personal details',
  'Please type your current password to change your email address.': 'Please type your current password to change your email address.',
  'Price': 'Price',
  'Privacy': 'Privacy',
  'Privacy Policy': 'Privacy Policy',
  'Proceed to payment': 'Proceed to payment',
  'Product': 'Product',
  'Product suggestions': 'Product suggestions',
  'Products': 'Products',
  'Products found': 'Products found',
  '{count} items': '{count} items',
  'Purchase terms': 'Purchase terms',
  'Quality in the details': 'Quality in the details',
  'Quantity': 'Quantity',
  'Read all reviews': 'Read all reviews',
  'Read and understand': 'I have read and understand the',
  'Read reviews': 'Read reviews',
  'Register': 'Register',
  'Register now': 'Register now',
  'Remove': 'Remove',
  'Remove Address': 'Remove Address',
  'Remove from wishlist': 'Remove from wishlist',
  'Confirm new password': 'Confirm new password',
  'Reset Password': 'Reset Password',
  'Review my order': 'Review my order',
  'Same as shipping address': 'Same as shipping address',
  'Save changes': 'Save changes',
  'Save for later': 'Save for later',
  'Save Password': 'Save Password',
  'Search': 'Search',
  'Search for items': 'Search for items',
  'Search results': 'Search results',
  'Sections that interest you': 'Sections that interest you',
  'See all results': 'See all results',
  'See more': 'See more',
  'Select payment method': 'Select payment method',
  'Select shipping method': 'Select shipping method',
  'Send my feedback': 'Send my feedback',
  'Set up newsletter': 'Set up your newsletter and we will send you information about new products and trends from the sections you selected every week.',
  'Select what interests you': 'Select what interests you',
  'Share your look': 'Share your look',
  'Shipping': 'Shipping',
  'Shipping address': 'Shipping address',
  'Shipping details': 'Shipping details',
  'Shipping method': 'Shipping method',
  'Show': 'Show',
  'show more': 'show more',
  'Show on page': 'Show on page',
  'Sign in': 'Sign in',
  'Sign In': 'Sign In',
  'Size guide': 'Size guide',
  'Size': 'Size',
  'Show size-table': 'Show size-table',
  'Sign Up for Newsletter': 'Sign Up for Newsletter',
  'Sign up to get 10% off your next purchase': 'Sign up to get 10% off your next purchase',
  'Personal': 'Personal',
  'Sale': 'Sale',
  'Sort by': 'Sort by',
  'Sort: Default': 'Default',
  'Sort: Name A-Z': 'Name A-Z',
  'Sort: Name Z-A': 'Name Z-A',
  'Sort: Price from high to low': 'Price from high to low',
  'Sort: Price from low to high': 'Price from low to high',
  'Start shopping': 'Start shopping',
  'Status': 'Status',
  'Subscribe': 'Subscribe',
  'Subscribe to newsletter': 'Subscribe to newsletter',
  'subscribeToNewsletterModalContent': 'After signing up for the newsletter, you will receive special offers and messages from VSF via email. We will not sell or distribute your email to any third party at any time. Please see our {0}.',
  'Subtotal': 'Subtotal',
  'Subtotal (Incl. Tax)': 'Subtotal (Incl. Tax)',
  'Tax {rate}%': 'Tax {rate}%',
  'Subtotal price': 'Subtotal price',
  'Successful placed order': 'You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.',
  'Terms and conditions': 'Terms and conditions',
  'Thank you': 'Thank you',
  'Request Thank You Inbox': 'Thank you for submitting your email address. Once your email address is successfully located in our system, we will send you an email with the information needed to reset your password. Please check your junk mail to ensure you receive it.',
  'Total': 'Total',
  'Order Total': 'Order Total',
  'Estimated Total': 'Estimated Total',
  'Total items': 'Total items',
  'Total price': 'Total price',
  'Update password': 'Update password',
  'Update personal data': 'Update personal data',
  'Use your personal data': 'At Brand name, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the',
  'User Account': 'User Account',
  'View': 'View',
  'View details': 'View details',
  'Vue Storefront Next': 'Vue Storefront Next',
  'Who we are': 'Who we are',
  'Wishlist': 'Wishlist',
  'Women fashion': 'Women fashion',
  'You added {product} to your shopping cart.': 'You added {product} to your shopping cart.',
  'Item added to your cart': 'Item added to your cart',
  '{qty} {productName} have been added to your cart': '{qty} {productName} have been added to your cart',
  'You are not authorized, please log in': 'You are not authorized, please log in',
  'You can unsubscribe at any time': 'You can unsubscribe at any time',
  'You currently have no orders': 'You currently have no orders',
  'You haven\’t searched for items yet': 'You haven’t searched for items yet.',
  'Your bag is empty': 'Your bag is empty',
  'Wishlist is empty': 'Wishlist is empty',
  'No favourites yet': 'No favourites yet',
  'Your current email address is': 'Your current email address is',
  'Your email*': 'Your email*',
  'The email field must be a valid email': 'The email field must be a valid email',
  'You have submitted no reviews': 'You have submitted no reviews',
  'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.': 'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.',
  'A customer with the same email address already exists in an associated website.': 'A customer with the same email address already exists in an associated website.',
  'Invalid email': 'Invalid email',
  'Learn more': 'Learn more',
  'Dresses': 'Dresses',
  'Cocktail & Party': 'Cocktail & Party',
  'Linen Dresses': 'Linen Dresses',
  'T-Shirts': 'T-Shirts',
  'The office life': 'The office life',
  'Find stunning women"s cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.': 'Find stunning women"s cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.',
  'Shop now': 'Shop now',
  'The Office Life': 'The Office Life',
  'Summer Sandals': 'Summer Sandals',
  'Eco Sandals': 'Eco Sandals',
  'Subscribe to Newsletters': 'Subscribe to Newsletters EN',
  'Subscribe today to receive 10% OFF': 'Subscribe today to receive 10% OFF',
  'Be aware of upcoming sales and events. Receive gifts and special offers!': 'Be aware of upcoming sales and events. Receive gifts and special offers',
  'Fashion to take away': 'Fashion to take away',
  'Download our application to your mobile': 'Download our application to your mobile',
  'Share Your Look': 'Share Your Look',
  'My Account': 'My Account',
  'Log in/Register': 'Log in/Register',
  'My profile': 'My profile',
  'My account': 'My account',
  'Personal Details': 'Personal Details',
  'Addresses details': 'Addresses details',
  'Newsletter': 'Newsletter',
  'My newsletter': 'My newsletter',
  'Language': 'Language',
  'Shipping Location': 'Shipping Location',
  'Change Location': 'Change Location',
  'Search location': 'Search location',
  'Contact Us': 'Contact Us',
  'CONTACT US': 'CONTACT US',
  'Go shopping': 'Go shopping',
  'Log out': 'Log out',
  'My reviews': 'My reviews',
  'Order history': 'Order history',
  'Order detail': 'Order detail',
  'Order details': 'Order details',
  'My wishlist': 'My wishlist',
  'Password change': 'Password change',
  'Personal data': 'Personal data',
  'Your e-mail': 'Your e-mail',
  'Current Password': 'Current Password',
  'You are not authorized, please log in.': 'You are not authorized, please log in.',
  'Go To Product': 'Go To Product',
  'Change to list view': 'Change to list view',
  'Change to grid view': 'Change to grid view',
  'Returns': 'Returns',
  'My orders': 'My orders',
  'Order detail #{orderId}': 'Order detail #{orderId}',
  'Add the address': 'Add the address',
  'Set as default shipping': 'Set as default shipping',
  'Set as default billing': 'Set as default billing',
  'House/Apartment number': 'House/Apartment number',
  'Street Name': 'Street Name',
  'City': 'City',
  'State/Province': 'State/Province',
  'Zip-code': 'Zip-code',
  'Country': 'Country',
  'Phone number': 'Phone number',
  'Please select a country first': 'Please select a country first',
  'This field is required': 'This field is required',
  'The field should have at least 2 characters': 'The field should have at least 2 characters',
  'The field should have at least 4 characters': 'The field should have at least 4 characters',
  'The field should have at least 8 characters': 'The field should have at least 8 characters',
  'The field should have at least 10 characters': 'The field should have at least 10 characters',
  'Enter correct phone number': 'Enter correct phone number',
  'New Password': 'New Password',
  'New Products': 'New Products',
  'There are no shipping methods available for this country. We are sorry, please try with different country.': 'There are no shipping methods available for this country. We are sorry, please try with different country.',
  'There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.': 'There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.',
  'There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.': 'There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.',
  'We can\'t find products matching the selection.': 'We can\'t find products matching the selection.',
  'Page not found': 'Page not found',
  'Back to Home page': 'Back to Home page',
  'Error': 'Error',
  'An error occurred': 'An error occurred',
  'Category': 'Category',
  'All Products From Category': 'All Products From Category',
  'See all': 'See all',
  'Show more': 'Show more',
  'Show less': 'Show less',
  'Yes': 'Yes',
  'No': 'No',
  'Apply filters': 'Apply filters',
  'The coupon code isn"t valid. Verify the code and try again.': 'The coupon code isn"t valid. Verify the code and try again.',
  'From': 'From',
  'To': 'To',
  'Your customization': 'Your customization',
  'Passwords don\'t match': 'Passwords don\'t match',
  'The password must be at least 6 characters long and must contain at least: 1 uppercase letter and 1 lowercase letter': 'The password must be at least 6 characters long and must contain at least: 1 uppercase letter and 1 lowercase letter',
  'Show all products': 'Show all products',
  'Select previously saved address': 'Select previously saved address',
  'Enter different address': 'Enter different address',
  'Success registration message': 'Welcome to Togas!',
  'Change Store': 'Change Store',
  'Choose Currency': 'Choose Currency',
  'Add a review': 'Add a review',
  'Add to cart': 'Add to cart',
  'Title': 'Title',
  'Name': 'Name',
  'Review': 'Review',
  'Reviews': 'Reviews',
  'Add review': 'Add review',
  'Only registered users can leave reviews. Please login or register.': 'Only registered users can leave reviews. Please login or register.',
  'Are you sure you would like to remove this item from the shopping cart?': 'Are you sure you would like to remove this item from the shopping cart?',
  'Your cart is empty': 'Your cart is empty',
  'Are you sure?': 'Are you sure?',
  '{0} has been successfully removed from your cart': '{0} has been successfully removed from your cart',
  'Amount': 'Amount',
  'Thank you for your order!': 'Thank you for your order!',
  'Your Purchase': 'Your Purchase',
  'Primary contacts for any questions': 'Primary contacts for any questions',
  'Your Account': 'Your account',
  'What can we improve': 'What can we improve',
  'Payment date': 'Payment date',
  'The user password was changed successfully updated!': 'The user password was changed successfully updated!',
  'The user account data was successfully updated!': 'The user account data was successfully updated!',
  'You submitted your review for moderation.': 'You submitted your review for moderation.',
  'Starting at': 'Starting at',
  'Bestsellers': 'Bestsellers',
  'New season': 'New season',
  'About Us': 'About Us',
  'Customer Care': 'Customer Care',
  'Care': 'Care',
  'Information': 'Information',
  'You have been successfully subscribed': 'You have been successfully subscribed',
  'Something went wrong': 'Something went wrong',
  'Blog': 'Blog',
  'Find your location': 'Find your location',
  'Shipping and returns': 'Shipping and returns',
  'Privacy policy': 'Privacy policy',
  'Gift Packaging': 'Gift Packaging',
  'Loyalty Program': 'Loyalty Program',
  'Quality control': 'Quality control',
  'Corporate social responsibility': 'Corporate social responsibility',
  'Corporate responsibility': 'Corporate responsibility',
  'Togas Custom Product': 'Togas Custom Product',
  'For designers and architects': 'For designers and architects',
  'Togas Contract (HoReCa)': 'Togas Contract (HoReCa)',
  'Franchise & Distribution': 'Franchise & Distribution',
  'Sustainability': 'Sustainability',
  'Partnerships': 'Partnerships',
  'Subscribe to newsletters': 'Subscribe to newsletters',
  'Enter your email address': 'Enter your email address',
  'By opting in I agree to Togas`s terms and conditions and understand I can opt out at any time': 'By opting in I agree to Togas`s terms and conditions and understand I can opt out at any time',
  'Find stunning women\'s cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.': 'Find stunning women\'s cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.',
  'No products found': 'No products found',
  'No categories found': 'No categories found',
  'Gifts for beloved ones': 'Gifts for beloved ones',
  'See catalog': 'See catalog',
  'Welcome, ': 'Welcome, ',
  'Welcome!': 'Welcome!',
  '{name}, Welcome home!': '{name}, Welcome home!',
  'Gift Packing': 'Gift Packing',
  'Toggle view': 'Toggle view',
  'We can\'t find products matching the selection': 'We can\'t find products matching the selection',
  'Shopping Cart': 'Shopping Cart',
  'Property': 'Property',
  'General': 'General',
  'Your phone number': 'Your phone number',
  'Send': 'Send',
  'Your message': 'Your message',
  'Something went wrong. Try it later.': 'Something went wrong. Try it later.',
  'Call us': 'Call us',
  'Send a message': 'Send a message',
  'Customer Service': 'Customer Service',
  'Europe Customer Service': 'Europe Customer Service',
  'USA Customer Service': 'USA Customer Service',
  'Middle East Customer Service': 'Middle East Customer Service',
  'Read more': 'Read more',
  'Read less': 'Read less',
  'Sorry, there are no results for "{term}"': 'Sorry, there are no results for "{term}"',
  'Search results for \'{term}\'': 'Search results for "{term}"',
  'Try a new search': 'Try a new search',
  'Collection': 'Collection',
  'All collection': 'All collection',
  'You haven`t searched for items yet.': 'You haven`t searched for items yet.',
  'Let`s start now - we`ll help you.': 'Let`s start now – we`ll help you.',
  'items': 'items',
  'Store Locator': 'Store Locator',
  'Boutiques': 'Boutiques',
  'Stores found: {storesFiltered}': 'Stores found: {storesFiltered}',
  'Enter City or Postcode': 'Enter City or Postcode',
  'Learn more here': 'Learn more here',
  ' km away from you': ' km away from you',
  'View all products': 'View all products',
  'Contact us success message': 'Thank you for reaching out. You will receive a response from us within 2 business days.',
  'This page does not exist': 'This page does not exist',
  'Out of Stock': 'Out of Stock',
  'In Stock': 'In Stock',
  'Choose country': 'Choose country',
  'Change language': 'Change language',
  'Collections': 'Collections',
  'Need to login message': 'You must log in or register to add items to your wishlist.',
  'Your subscribe data was successfully updated!': 'Your subscribe data was successfully updated!',
  'What can we improve?': 'What can we improve?',
  'back to homepage': 'back to homepage',
  'Latest updates': 'Latest updates',
  'All': 'All',
  'news & events': 'news & events',
  'blog banner description': 'Explore our newest articles, and latest updates so you can get all the information you need to achieve a high quality, restful sleep',
  'No posts found': 'No posts found',
  'Related': 'Related',
  'Share': 'Share',
  'Remove from Cart': 'Remove from Cart',
  'Promo': 'Promo',
  'Promos': 'Promos',
  'Promotions': 'Promotions',
  'Active Promos': 'Active Promos',
  'No active promos found': 'No active promos found',
  'See more products': 'See more products',
  'Date of Birth': 'Date of Birth',
  'Once the data has been saved it cannot be changed.': 'Once the data has been saved it cannot be changed.',
  'You may also like': 'You may also like',
  'The World of Togas': 'The World of Togas',
  'Business inquiries': 'Business inquiries',
  'Boutique': 'Boutique',
  'Send a message to our {name} store here': 'Send a message to our {name} store here',
  'Enter a correct email': 'Enter a correct email',
  'Enter a correct phone number': 'Enter a correct phone number',
  'Back to Store Locator': 'Back to Store Locator',
  'Discounts': 'Discounts',
  'The collection includes': 'The collection includes',
  'Shipment and Returns': 'Shipment and Returns',
  'Terms and Conditions': 'Terms and Conditions',
  'Reach out to our Client Services': 'Reach out to our Client Services',
  'Orders & Shipment': 'Orders & Shipment',
  'Returns & Exchanges': 'Returns & Exchanges',
  'Order Cancellation': 'Order Cancellation',
  'Cookies & Other Technologies': 'Cookies & Other Technologies',
  'Social Media': 'Social Media',
  'Privacy and Security': 'Privacy and Security',
  'View shopping cart': 'View shopping cart',
  'Item added to Wishlist': 'Item added to Wishlist',
  'Item removed from Wishlist': 'Item removed from Wishlist',
  'Double click for Zoom': 'Double click for Zoom',
  'Your newsletter subscription has been cancelled': 'Your newsletter subscription has been cancelled',
  'You have successfully signed up for our newsletter': 'You have successfully signed up for our newsletter',
  'Unfortunately, your attempt to sign up for our newsletter has failed. Please try again': 'Unfortunately, your attempt to sign up for our newsletter has failed. Please try again',
  'User with this email doesn’t exist.': 'User with this email doesn’t exist.',
  'Additional Items': 'Additional Items',
  'Add Review': 'Add Review',
  'Visit Sensotex website': 'Visit Sensotex website',
  'Your desired amount of {ProductName} you have requested is not available at this time': 'Your desired amount of {ProductName} you have requested is not available at this time',
  'More about the collection': 'More about the collection',
  'Payment failed. Please try another payment method.': 'Payment failed. Please try another payment method.',
  'Lezotex': 'Lezotex',
  'Climafiber': 'Climafiber',
  'Sensotex': 'Sensotex',
  'Services': 'Services',
  'My Togas Rewards status': 'My Togas Rewards status',
  'level': 'level',
  'loyalty page link message': 'Click the link here to view any further information, including our terms and conditions.',
  'Accumulated amount {value}': 'Accumulated amount {value}',
  'To reach the next level {value}': 'To reach the next level {value}',
  'Go to Store Locator': 'Go to Store Locator',
  'Notify me': 'Notify me',
  'Would you like to be notified when this item becomes available?': 'Would you like to be notified when this item becomes available?',
  'I would like to receive notification about availability of stock.': 'I would like to receive notification about availability of stock.',
  'We will notify once this item is available.': 'We will notify once this item is available.',
  'The product that was requested doesn\'t exist. Verify the product and try again': 'The product that was requested doesn\'t exist. Verify the product and try again',
  'Enter your e-mail address below and we’ll let you know': 'Enter your e-mail address below and we’ll let you know',
  'Accept': 'Accept',
  'Impressum': 'Impressum',
  'Togas Technologies': 'Togas Technologies',
  'Full Details': 'Full Details',
  'Start over': 'Start over',
  'Explore more': 'Explore more',
  'Clear cart': 'Clear cart',
  'Are you sure to remove all items from the shopping cart?': 'Are you sure to remove all items from the shopping cart?',
  'Gift packaging': 'Gift packaging',
  'Please fill in your real first name and last name.': 'Please fill in your real first name and last name.',
  'Sitemap': 'Sitemap',
  'Warning for Apple Users title': 'Warning for Apple Users:',
  'Warning for Apple Users message': 'Please make sure not to activate Hide My Email feature on your Apple device. We need your email address to contact you regarding your order.',
  'Save new address': 'Save new address',
  'Set new address as default (Check "Save new address" first)': 'Set new address as default (Check "Save new address" first)',
  'Shipping/Billing address': 'Shipping/Billing address',
  'You have successfully registered.': 'You have successfully registered.',
  'BeddingInDubai': 'BeddingInDubai',
};
