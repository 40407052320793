export const touch = {

  bind(el, binding) {
    const actionType = binding.arg || 'swipe-right';
    const actionHandler = binding.value;
    const sensitivity = 20;

    let touchStart = null,
      touchPosition = null;

    const checkAction = (e) => {
      const d = {
        x: touchStart.x - touchPosition.x,
        y: touchStart.y - touchPosition.y
      };

      let action = '';

      if (Math.abs(d.x) > Math.abs(d.y)) {
        if (Math.abs(d.x) > sensitivity) {
          d.x > 0 ? action = 'swipe-left' :  action = 'swipe-right'
        }
      }
      else {
        if (Math.abs(d.y) > sensitivity) {
          d.y > 0 ? action = 'swipe-up' : action = 'swipe-down'
        }
      }

      if (actionType === action) {
        actionHandler(e);
      }
    }

    el._touchStartHandler = (e) => {
      touchStart = { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY };
      touchPosition = { x: touchStart.x, y: touchStart.y };
    };

    el._touchMoveHandler = (e) => {
      touchPosition = { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY };
    };

    el._touchEndHandler = (e) => {
      checkAction(e);

      touchStart = null;
      touchPosition = null;
    };

    el.addEventListener("touchstart", el._touchStartHandler);
    el.addEventListener("touchmove", el._touchMoveHandler);
    el.addEventListener("touchend", el._touchEndHandler);
  },

  unbind(el) {
    el.removeEventListener("touchstart", el._touchStartHandler);
    el.removeEventListener("touchmove", el._touchMoveHandler);
    el.removeEventListener("touchend", el._touchEndHandler);
  },
};
