import {
  readonly,
  ref,
  useContext
} from '@nuxtjs/composition-api';

import type { Ref } from '@nuxtjs/composition-api';

import {
  PaymentResolveInterface,
  PaymentUnresolveInterface
} from '~/modules/GraphQL/types';

type PaymentResolveResult = PaymentResolveInterface | PaymentUnresolveInterface;

interface UsePaymentResolveInterface {

  /** Indicates whether any of the composable methods is in progress. */
  loading: Readonly<Ref<boolean>>;

  resolvePayment(payment_code: string, payment_id: string): Promise<PaymentResolveResult | null>;
}

export const usePaymentResolve = (): UsePaymentResolveInterface => {
  const { app } = useContext();

  const loading = ref(false);
  const paymentInfo = ref<PaymentResolveInterface | null>(null);

  const resolvePayment = async (paymentCode: string, paymentId: string, ): Promise<PaymentResolveResult | null> => {

    try {
      loading.value = true;
      // @ts-ignore
      const response = await app.$vsf.$magento.api.handlePaymentCallback(paymentCode, paymentId)

      // @ts-ignore
      if (response && response.data && response.data.handlePaymentCallback) {
        // @ts-ignore
        paymentInfo.value = response.data.handlePaymentCallback;
      } else if(response && response.errors && response.errors[0]) {
        // @ts-ignore
        paymentInfo.value = response.errors[0];
      }

    } catch (err) {
      paymentInfo.value = err;
    } finally {
      loading.value = false;
    }

    return paymentInfo.value;
  };

  return {
    resolvePayment,
    loading: readonly(loading),
  };
};

export default usePaymentResolve;
