import { CountrySelectorItem, useApi } from "~/composables/";
import { setItem } from "~/helpers/asyncLocalStorage";


export function useCountries() {
  const changeCountry = async (country: CountrySelectorItem) => {
    await setItem('selected-country', country.code || country.name);
    await setItem('selected-country-icon', country.icon_url);
  };

  return {
    changeCountry,
  };
}

export default useCountries;
