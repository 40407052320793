<template>
  <div v-show="initialTabActivated" class="sf-tabs" ref="tabs">
    <slot />
  </div>
</template>
<script>
import Vue from 'vue';
import SfTab from './_internal/SfTab.vue';

Vue.component('SfTab', SfTab);
import VueScrollTo from 'vue-scrollto';

export default {
  name: 'SfTabs',
  props: {
    openTab: {
      type: Number,
      default: 1,
    },
    tabMaxContentHeight: {
      type: String,
      default: '',
    },
    tabShowText: {
      type: String,
      default: 'show',
    },
    tabHideText: {
      type: String,
      default: 'hide',
    },
    desktopMin: {
      type: Number,
      default: 1024,
    },
    singleOpen: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      initialTabActivated: false,
    };
  },
  watch: {
    openTab(newValue, oldValue) {
      if(newValue === oldValue) return;
      this.toggle(this.$children[newValue - 1]._uid);
    },
  },
  mounted() {
    this.$on('toggle', this.toggle);

    const mobileWidthMediaQuery = window.matchMedia(`(max-width: ${this.desktopMin}px)`);
    mobileWidthMediaQuery.addEventListener('change', () => this.resetOpenTabs());

    if(this.openTab) this.openChild();

    if(process.client) {
      const queryActiveTab = +this.$route.query['activeTab'] || null;
      if(queryActiveTab
        && queryActiveTab <= this.$children.length && queryActiveTab > 0) {
        VueScrollTo.scrollTo(this.$refs.tabs, 500, {lazy: false, offset: -100});
        this.toggle(this.$children[queryActiveTab - 1]._uid);
      }
    }
  },
  methods: {
    toggle(id) {
      const width = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth
      );
      if(width <= this.desktopMin) {
        const child = this.$children.find(child => child._uid === id);
        if(this.singleOpen) {
          this.$children.forEach(child => {
            child.isActive = false;
          });
          child.isActive = true;
        } else {
          child.isActive = !child.isActive;
        }
      } else {
        this.$children.forEach((child) => {
          child.isActive = child._uid === id;
        });
      }
      const activeTabs = this.$children.filter((child) => child.isActive).map((child) => child._uid);
      this.$emit('click:tabs', activeTabs);
    },
    openChild() {
      if(this.openTab < this.$children.length + 1) {
        this.$children[this.openTab - 1].isActive = true;
        this.initialTabActivated = true;
      }
    },
    resetOpenTabs() {
      this.$children.forEach((child) => child.isActive = false);
      this.openChild();
    },
  },
  provide: function () {
    const tabConfig = {};
    Object.defineProperty(tabConfig, 'tabMaxContentHeight', {
      get: () => this.tabMaxContentHeight,
    });
    Object.defineProperty(tabConfig, 'tabShowText', {
      get: () => this.tabShowText,
    });
    Object.defineProperty(tabConfig, 'tabHideText', {
      get: () => this.tabHideText,
    });
    Object.defineProperty(tabConfig, 'desktopMin', {
      get: () => this.desktopMin,
    });
    return {
      tabConfig,
    };
  },
};
</script>
<style lang="scss">
@import "~@/styles/components/organisms/SfTabs.scss";
</style>
