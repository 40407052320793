import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export function useMap() {

  let map: google.maps.Map;

  const markerIcon = {
    path: 'M 6.066 4.051 C 9.568 1.24 14.003 0 17.832 0 C 21.697 0 26.209 1.448 29.77 4.337 C 33.36 7.25 36 11.643 36 17.467 C 36 19.867 35.225 22.572 34.045 25.316 C 32.859 28.076 31.227 30.957 29.432 33.734 C 25.842 39.288 21.543 44.51 18.67 47.632 A 1.139 1.139 0 0 1 17.003 47.642 C 14.18 44.64 9.96 39.564 6.439 34.049 C 2.953 28.593 0 22.48 0 17.469 C 0 11.299 2.529 6.892 6.066 4.05 Z M 7.493 5.821 C 4.487 8.235 2.276 11.994 2.276 17.467 C 2.276 21.769 4.888 27.395 8.358 32.828 C 11.458 37.681 15.122 42.199 17.824 45.178 C 20.587 42.078 24.344 37.415 27.52 32.503 C 29.273 29.791 30.834 27.024 31.954 24.42 C 33.08 21.8 33.724 19.423 33.724 17.467 C 33.724 12.404 31.456 8.632 28.335 6.1 C 25.185 3.546 21.182 2.272 17.832 2.272 C 14.447 2.272 10.535 3.378 7.492 5.822 Z M 10 17.279 H 10.53 L 12.826 12.411 H 14.698 C 15.158 12.411 15.475 12.481 15.652 12.623 C 15.828 12.763 15.899 13.081 15.899 13.646 V 28.249 C 15.899 28.778 15.758 29.201 15.581 29.519 C 15.334 29.871 14.804 30.189 13.956 30.471 V 31 H 22.046 V 30.47 C 21.197 30.189 20.632 29.871 20.42 29.519 A 2.693 2.693 0 0 1 20.102 28.249 V 13.61 C 20.102 13.081 20.173 12.728 20.35 12.587 C 20.597 12.482 20.915 12.411 21.374 12.411 H 23.246 L 25.578 17.243 H 26.037 V 11 H 10 V 17.279 Z',
      fillColor: 'black',
      fillOpacity: 1,
      strokeWeight: 0,
      scale: 1,
  };

  const createMap = (element, language= 'en', options = {}, styles = []) => {

    const defaultOptions = {
      center: {lat: 40.743476, lng: -73.988671},
      zoom: 14,
      disableDefaultUI: true,
      mapTypeControl: false,
      streetViewControl: false,
      scaleControl: false,
      zoomControl: true,
      fullscreenControl: true,
      keyboardShortcuts: false,
      backgroundColor: '#f2f2f2',
    };

    const defaultStyles = [
      {
        'featureType': 'water',
        'elementType': 'geometry',
        'stylers': [{'color': '#e9e9e9'}, {'lightness': 17}]
      }, {
        'featureType': 'landscape',
        'elementType': 'geometry',
        'stylers': [{'color': '#f5f5f5'}, {'lightness': 20}]
      }, {
        'featureType': 'road.highway',
        'elementType': 'geometry.fill',
        'stylers': [{'color': '#ffffff'}, {'lightness': 17}]
      }, {
        'featureType': 'road.highway',
        'elementType': 'geometry.stroke',
        'stylers': [{'color': '#ffffff'}, {'lightness': 29}, {'weight': 0.2}]
      }, {
        'featureType': 'road.arterial',
        'elementType': 'geometry',
        'stylers': [{'color': '#ffffff'}, {'lightness': 18}]
      }, {
        'featureType': 'road.local',
        'elementType': 'geometry',
        'stylers': [{'color': '#ffffff'}, {'lightness': 16}]
        }, {
        'featureType': 'poi',
        'elementType': 'geometry',
        'stylers': [{'color': '#f5f5f5'}, {'lightness': 21}]
      }, {
        'featureType': 'poi.park',
        'elementType': 'geometry',
        'stylers': [{'color': '#dedede'}, {'lightness': 21}]
      }, {
        'elementType': 'labels.text.stroke',
        'stylers': [{'visibility': 'on'}, {'color': '#ffffff'}, {'lightness': 16}]
      }, {
        'elementType': 'labels.text.fill',
        'stylers': [{'saturation': 36}, {'color': '#333333'}, {'lightness': 40}]
        }, {
        'elementType': 'labels.icon',
        'stylers': [{'visibility': 'off'}]
      }, {
        'featureType': 'transit',
        'elementType': 'geometry',
        'stylers': [{'color': '#f2f2f2'}, {'lightness': 19}]
      }, {
        'featureType': 'administrative',
        'elementType': 'geometry.fill',
        'stylers': [{'color': '#fefefe'}, {'lightness': 20}]
      }, {
        'featureType': 'administrative',
        'elementType': 'geometry.stroke',
        'stylers': [{'color': '#fefefe'}, {'lightness': 17}, {'weight': 1.2}]}
    ];

    const loader = new Loader({
      apiKey: 'AIzaSyCktmPVIAoizu9G5f_hfjotrSirddK2vjk',
      language: language,
    });

    loader.load().then(async () => {
      const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;
      map = new Map(element, { ...defaultOptions, ...options, ...{ styles: [...defaultStyles, ...styles] }});
    });
  };

  const getCurrentPosition = () => {
    if (navigator.geolocation) {
      return new Promise((res, rej) => {
        navigator.geolocation.getCurrentPosition(res, rej);
      });
    }
    return;
  };

  const getDistance = (storePosition, currentPosition) => {
    if (!currentPosition) return '';

    const haversineDistance = (mk1, mk2) => {
      const R = 6371.071, // For km! For miles 3958.8
        rlat1 = mk1.lat * (Math.PI / 180),
        rlat2 = mk2.coords.latitude * (Math.PI / 180),
        difflat = rlat2 - rlat1,
        difflon = (mk2.coords.longitude - mk1.lng) * (Math.PI / 180);
      return Math.floor(2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2))));
    }
    return haversineDistance(storePosition, currentPosition);
  };

  const setPosition = (position, zoom = 16) => {
    map.setCenter(position);
    map.setZoom(zoom);
  };

  const setMarkers = async (stores = []) => {
    const { Marker } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary;

    const markers = [];

    const setMarker = (item) => {
      const title = item.name,
        position = { lat: +item.address.latitude, lng: +item.address.longitude };

      const marker = new Marker({
        map: map,
        position: position,
        title: title,
        optimized: true,
        icon: Object.assign(markerIcon, { anchor: new google.maps.Point(18, 48)}),
      });

      markers.push(marker);
    }

    if (Array.isArray(stores)) {
      stores.forEach((item) => setMarker(item))
    } else {
      setMarker(stores);
    }

    return markers;
  };

  const setMarkerCluster = async (stores = []) => {
    const { Marker } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary;
    const markers = [];

    stores.forEach((item) => {
      const title = item.name,
        position = { lat: +item.address.latitude, lng: +item.address.longitude };

      const marker = new Marker({
        position: position,
        title: title,
        optimized: true,
        icon: Object.assign(markerIcon, { anchor: new google.maps.Point(18, 48)}),
      });

      markers.push(marker);
    });

    const renderer = {
      render: ({ count, position }) =>
        new google.maps.Marker({
          label: { text: String(count), color: 'black', fontSize: '16px', fontWeight: 'bold' },
          icon: Object.assign(markerIcon, { anchor: new google.maps.Point(18, 48)}),
          position,
        }),
    };
    new MarkerClusterer({ map, markers, renderer });

    return markers;
  };

  const fitMarkers = (markers) => {
    const markersBounds = new google.maps.LatLngBounds();

    for (let i = 0; i < markers.length; i++) {
      const markersPosition = new google.maps.LatLng(markers[i].address.latitude, markers[i].address.longitude);
      markersBounds.extend(markersPosition);
      map.fitBounds(markersBounds);
    }
  };

  return {
    createMap,
    getCurrentPosition,
    setPosition,
    setMarkers,
    setMarkerCluster,
    fitMarkers,
    getDistance
  }
}

export default useMap;
