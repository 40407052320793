import { Ref, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type{ CategoryTree } from '~/modules/GraphQL/types';
import categoryMetaGql from '~/modules/catalog/category/composables/useCategory/categoryMeta.gql';
import NavigationMenuTreeList from '~/modules/catalog/category/composables/useCategory/navigationMenuTreeList.gql';
import type {
  UseCategoryErrors,
  UseCategoryInterface,
  UseCategoryParamsInput,
  UseCategoryMetaParamsInput,
} from './useCategory';

export function useCategory(): UseCategoryInterface {
  const { app } = useContext();
  const loading: Ref<boolean> = ref(false);
  const error: Ref<UseCategoryErrors> = ref({
    load: null,
    loadCategoryMeta: null,
  });
  const categories: Ref<Array<CategoryTree>> = ref(null);

  const load = async (params: UseCategoryParamsInput) => {
    Logger.debug('useCategory/load', params);

    try {
      loading.value = true;
      // const { data } = await app.context.$vsf.$magento.api.categoryList(params, params?.customQuery ?? null, params?.customHeaders);

      const { data } = await app.context.$vsf.$magento.api.customQuery({
        query: NavigationMenuTreeList,
        queryVariables: params,
        customHeaders: params?.customHeaders,
      });

      Logger.debug('[Result]:', { data });
      categories.value = data?.navigationMenuTreeList?.items ?? [];
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useCategory/load', err);
    } finally {
      loading.value = false;
    }
  };

  const loadCategoryMeta = async (params: UseCategoryMetaParamsInput): Promise<CategoryTree | null> => {
    Logger.debug('useCategory/loadCategoryMeta', params);
    let categoryMeta = null;

    try {
      loading.value = true;

      const { data } = await app.context.$vsf.$magento.api.customQuery({
        query: categoryMetaGql,
        queryVariables: {
          filters: {
            category_uid: {
              eq: params.category_uid,
            },
          },
        },
        customHeaders: params?.customHeaders,
      });
      Logger.debug('[Result]:', { data });
      categoryMeta = data.categories?.items[0] || null;
      error.value.loadCategoryMeta = null;
    } catch (err) {
      error.value.loadCategoryMeta = err;
      Logger.error('useCategory/loadCategoryMeta', err);
    } finally {
      loading.value = false;
    }

    return categoryMeta;
  };

  return {
    load,
    loadCategoryMeta,
    loading,
    error,
    categories,
  };
}

export * from './useCategory';

export default useCategory;
