import { PiniaPluginContext } from 'pinia';
import { Plugin } from '@nuxt/types';
import { ref, set } from '@nuxtjs/composition-api';
import type { StoreConfig, Currency } from '~/modules/GraphQL/types';

const storeConfigPlugin: Plugin = async ({ $pinia, app }) => {
  const { data }: {
    data: {
      storeConfig?: StoreConfig,
      availableStores?: StoreConfig,
      currency?: Currency,
    }
  } = await app.$vsf.$magento.api.storeConfig();

  $pinia.use(({ store }: PiniaPluginContext) => {
    if (store.$id !== 'magentoConfig') return;
    const storeConfig = ref(data?.storeConfig ?? {});
    const currency = ref(data?.currency ?? {});
    const stores = ref(data?.availableStores ?? {});

    if (!store.$state.hasOwnProperty('storeConfig')) {
      set(store.$state, 'storeConfig', storeConfig);
    } else {
      store.$state.storeConfig = storeConfig;
    }

    if (!store.$state.hasOwnProperty('stores')) {
      set(store.$state, 'stores', stores);
    } else {
      store.$state.stores = stores;
    }

    if (!store.$state.hasOwnProperty('currency')) {
      set(store.$state, 'currency', currency);
    } else {
      store.$state.currency = currency;
    }
  });
};

export default storeConfigPlugin;
