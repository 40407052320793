export const INT_WEBSITE_CODE = 'togas_int_website';
export const AT_WEBSITE_CODE = 'togas_at_website';
export const ME_WEBSITE_CODE = 'togascom_me';

export const PromoBannerGroupType = {
  MAIN_SLIDER: { identifier: 'main_slider' },
  SLEEP_SYSTEM: { identifier: 'sleep-system' },
  TECHNOLOGIES: { identifier: 'technologies' },
  FASHION_SLIDER: { identifier: 'fashion_slider' },
  BOUTIQUES: { identifier: 'boutiques' },
} as const;
