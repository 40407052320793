import { Middleware } from '@nuxt/types';

const urlActionHandler : Middleware = async (context) => {
  const { query } = context.route;

   if (query.hasOwnProperty('ref')) {
       switch (query.ref) {
         case 'passwordReset':
           context.redirect(context.localeRoute({
             name: 'customer-login-register',
             query : query
           }));
           break;
         default:
           break;
       }
   }
};

export default urlActionHandler;
