<template>
  <section class="sf-review">
    <slot name="image" />
    <div>
      <slot name="author" v-bind="{ author }">
        <div class="sf-review__author">
          {{ author }}
        </div>
      </slot>
      <slot name="info" v-bind="{ rating, maxRating, date }">
        <div class="sf-review__info">
          <div :class="{ 'sf-review__rating': rating > 0 && maxRating > 0 }">
            <SfRating v-if="rating" :max="maxRating" :score="rating" />
          </div>
          <div class="sf-review__date">
            {{ date }}
          </div>
        </div>
      </slot>
      <slot name="message" v-bind="{ finalMessage, buttonText }">
        <div :class="{ 'display-none': !message }">
          <p class="sf-review__message">{{ finalMessage }}</p>
          <SfButton
            :class="{ 'display-none': !showButton }"
            class="sf-button--text sf-review__read-more"
            @click="toggleMessage"
          >
            {{ buttonText }}
          </SfButton>
        </div>
      </slot>
    </div>
  </section>
</template>
<script>
import { SfButton, SfRating } from '~/components';

export default {
  name: 'SfReview',
  components: {
    SfRating,
    SfButton,
  },
  props: {
    author: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    rating: {
      type: [Number, String, Boolean],
      default: false,
    },
    maxRating: {
      type: [Number, String],
      default: 5,
    },
    charLimit: {
      type: [Number, String],
      default: 250,
    },
    readMoreText: {
      type: String,
      default: 'Read more',
    },
    hideFullText: {
      type: String,
      default: 'Read less',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    showButton() {
      return this.message.length > this.charLimit;
    },
    buttonText() {
      let buttonText = this.readMoreText;
      if(this.isOpen) {
        buttonText = this.hideFullText;
      }
      return buttonText;
    },
    finalMessage() {
      return this.message.length > this.charLimit && !this.isOpen
          ? this.message.slice(0, this.charLimit) + '...'
          : this.message;
    },
  },
  methods: {
    toggleMessage() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
<style lang="scss">
@import "~@/styles/components/molecules/SfReview.scss";
</style>
