import { ProductAttributeSortInput } from '~/modules/GraphQL/types';

export function createProductAttributeSortInput(sortData: string): ProductAttributeSortInput {
  const splitterIndex = sortData.lastIndexOf('_');
  const baseData = splitterIndex !== -1
    ? [sortData.slice(0, splitterIndex), sortData.slice(splitterIndex + 1)]
    :  [];

  return baseData.length > 0 ? Object.fromEntries([baseData]) : {};
}
