import { ref } from '@nuxtjs/composition-api';
import { useConfigStore } from "~/stores/config";

export const useTopBar = () => {
  const { stores, currency } = useConfigStore();
  const hasStoresToSelect = ref<boolean>(false);
  const hasCurrencyToSelect = ref<boolean>(false);

  hasStoresToSelect.value = stores?.length > 1 ?? false;
  hasCurrencyToSelect.value = currency?.available_currency_codes?.length > 1 ?? false;

  return {
    hasStoresToSelect,
    hasCurrencyToSelect,
  };
};

export default useTopBar;
