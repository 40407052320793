export const IN_STOCK_TEXT = 'In Stock';
export const IN_STOCK_ICON = 'in-stock';
export const IN_STOCK_COLOR = '#686864';

export const OUT_OF_STOCK_TEXT = 'Out of Stock';
export const OUT_STOCK_ICON = 'out-of-stock';
export const OUT_OF_STOCK_COLOR = '#DD0000';

export const ADD_TO_CART_BTN_TEXT = 'Add to cart';

export enum ProductPromoTypes {
  BEDLINE_PALLADA = 'bed_linen_set_pallada',
  PILLOW_SOVEREIGN = 'pillow_sovereign',
  DUVET_INNOTEX = 'duvet_innotex',
  PILLOW_MINORIS = 'pillow_minoris',
  DUVET_INFINITY = 'duvet_infinity',
  PILLOW_NOBILIS = 'pillow_nobilis',
  PILLOW_KAISER = 'pillow_kaiser',
  PILLOW_INFINITY = 'pillow_infinity',
  DUVET_PALAZZO = 'duvet_palazzo',
  PILLOW_ANTHRACITE_M = 'pillow_anthracite_m',
  PILLOW_ANTHRACITE_L = 'pillow_anthracite_l',
  PILLOW_CUPRUM = 'pillow_cuprum',
  PILLOW_AQUARIUS = 'pillow_aquarius',
  PILLOW_SIRIUS = 'pillow_sirius',
  PILLOW_ATLANTIS = 'pillow_atlantis',
  PILLOW_ELEMENT = 'pillow_element',
}

