import {
  computed,
  readonly,
  ref,
  useContext,
  useRoute,
} from '@nuxtjs/composition-api';
import { addItemCommand } from '~/modules/checkout/composables/useCart/commands/addItemCommand';
import { applyCouponCommand } from '~/modules/checkout/composables/useCart/commands/applyCouponCommand';
import { loadCartCommand } from '~/modules/checkout/composables/useCart/commands/loadCartCommand';
import { clearCartCommand } from "~/modules/checkout/composables/useCart/commands/clearCartCommand";
import { loadTotalQtyCommand } from '~/modules/checkout/composables/useCart/commands/loadTotalQtyCommand';
import { removeCouponCommand } from '~/modules/checkout/composables/useCart/commands/removeCouponCommand';
import { removeItemCommand } from '~/modules/checkout/composables/useCart/commands/removeItemCommand';
import { updateItemQtyCommand } from '~/modules/checkout/composables/useCart/commands/updateItemQtyCommand';
import { Logger } from '~/helpers/logger';
import { Cart, CartItemInterface, ProductInterface, ProductStockStatus } from '~/modules/GraphQL/types';
import { useCartStore } from '~/modules/checkout/stores/cart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { Product } from '~/modules/catalog/product/types';
import { ComposableFunctionArgs, useUiNotification } from '~/composables';
import { UseCartErrors, UseCartInterface } from './useCart';
import { sendEcommerce } from '~/utilities/helpers';

/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */
export function useCart<CART extends Cart, CART_ITEM extends CartItemInterface, PRODUCT extends ProductInterface>(): UseCartInterface<CART,
  CART_ITEM,
  PRODUCT> {
  const loading = ref<boolean>(false);
  const error = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
  });
  const { app } = useContext();
  const context = app.$vsf;
  const route = useRoute();
  const cartStore = useCartStore();
  const cart = computed(() => cartStore.cart as CART);
  const apiState = context.$magento.config.state;
  const { loading: wishlistLoading, afterAddingWishlistItemToCart } = useWishlist();
  const { send: sendNotification } = useUiNotification();

  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = (newCart: CART): void => {
    Logger.debug('useCart.setCart', newCart);

    cartStore.$patch((state) => {
      state.cart = newCart;
    });
  };

  /**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
  const isInCart = (product: PRODUCT): boolean => !!cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid);

  const load = async ({ customQuery = {}, customHeaders = {}, realCart = false } = {
    customQuery: { cart: 'cart' },
    customHeaders: {}
  }): Promise<void> => {
    Logger.debug('useCart.load');

    try {
      loading.value = true;
      const loadedCart = await loadCartCommand.execute(context, { customQuery, customHeaders, realCart });
      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useCart/load', err);
    } finally {
      loading.value = false;
    }
  };

  const clear = async ({ customQuery, customHeaders } = { customQuery: { cart: 'cart' }, customHeaders: {} }): Promise<void> => {
    Logger.debug('useCart.clear');

    try {
      loading.value = true;

      const currentCart = cart.value.id;

      const loadedCart = await clearCartCommand.execute(context, { currentCart, customQuery, customHeaders });

      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
    } catch (err) {
      error.value.clear = err;
      Logger.error('useCart/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const loadTotalQty = async (params?: ComposableFunctionArgs<{}>): Promise<void> => {
    Logger.debug('useCart.loadTotalQty');

    try {
      loading.value = true;
      const totalQuantity = await loadTotalQtyCommand.execute(context, params);

      cartStore.$patch((state) => {
        state.cart.total_quantity = totalQuantity;
      });
    } catch (err) {
      error.value.loadTotalQty = err;
      Logger.error('useCart/loadTotalQty', err);
    } finally {
      loading.value = false;
    }
  };

  const addItem = async ({
                           product, quantity, productConfiguration, customQuery, customHeaders,
                         }): Promise<void> => {
    Logger.debug('useCart.addItem', { product, quantity });

    try {
      loading.value = true;

      if (!apiState.getCartId()) {
        await load({ realCart: true });
      }

      const updatedCart = await addItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        quantity,
        productConfiguration,
        customQuery,
        customHeaders,
      });

      error.value.addItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
      sendNotification({
        id: Symbol('add_item'),
        message: app.i18n.t('{qty} {productName} have been added to your cart', {
          productName: `${product.name.trim()}${quantity > 1 ? 's' : ''}`,
          qty: quantity
        }) as string,
        type: 'success',
        icon: 'check',
        persist: false,
      });
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useCart/addItem', err);
    } finally {
      if (!wishlistLoading.value && route.value.query?.wishlist) {
        afterAddingWishlistItemToCart({
          product,
          cartError: error.value.addItem,
        });
      }
      loading.value = false;

      // Ecommerce and Pixels
      window.dataLayer = window.dataLayer || [];
      sendEcommerce(
        'add_to_cart',
        'add_product',
        Object.assign(product, { quantity: quantity }),
        {
          'goods_id': product.sku,
          'goods_price': product.price_range.minimum_price.final_price.value
        }
      );
    }
  };

  const removeItem = async ({ product, customQuery, customHeaders }) => {
    Logger.debug('useCart.removeItem', { product });

    try {
      loading.value = true;

      // Ecommerce and Pixels
      window.dataLayer = window.dataLayer || [];
      sendEcommerce(
        'remove_from_cart',
        'cart',
        Object.assign(
          product.product,
          { quantity: product.quantity },
          {
            configurable_product_options_selection: {
              variant: {
                uid: product.uid,
                price_range: {
                  minimum_price: {
                    final_price: {
                      value: product.__typename === 'ConfigurableCartItem'
                        ? product.configured_variant.price_range.minimum_price.final_price.value
                        : product.prices.row_total.value
                    },
                    regular_price: {
                      value: product.__typename === 'ConfigurableCartItem'
                        ? product.configured_variant.price_range.minimum_price.regular_price.value
                        : product.prices.row_total.value
                    },
                  }
                }
              }
            }
          },
          { discount: product.prices.total_item_discount.value.toString() }
        ),
        {
          'goods_id': product.product.sku,
          'goods_price': product.prices.row_total.value,
        }
      );

      const updatedCart = await removeItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        customQuery,
        customHeaders,
      });

      error.value.removeItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useCart/removeItem', err);
    } finally {
      loading.value = false;
    }
  };

  const updateItemQty = async ({ product, quantity, customQuery = { updateCartItems: 'updateCartItems' } }): Promise<void> => {
    Logger.debug('useCart.updateItemQty', {
      product,
      quantity,
    });

    if (quantity && quantity > 0) {
      try {
        loading.value = true;
        const updatedCart = await updateItemQtyCommand.execute(context, {
          currentCart: cart.value,
          product,
          quantity,
          customQuery,
        });

        error.value.updateItemQty = null;
        cartStore.$patch((state) => {
          if (updatedCart.id) {
            state.cart = updatedCart;
          } else {
            sendNotification({
              id: Symbol('qty_update_error'),
              message: updatedCart.toString(),
              type: 'danger',
              icon: 'info_shield',
              persist: false,
            });
          }
        });
      } catch (err) {
        error.value.updateItemQty = err;
        Logger.error('useCart/updateItemQty', err);
      } finally {
        loading.value = false;

        // Ecommerce and Pixels
        window.dataLayer = window.dataLayer || [];
        sendEcommerce(
          quantity < product.quantity ? 'remove_from_cart' : 'add_to_cart',
          'cart',
          Object.assign(
            product.product,
            { quantity: product.quantity },
            {
              configurable_product_options_selection: {
                variant: {
                  uid: product.uid,
                  price_range: {
                    minimum_price: {
                      final_price: {
                        value: product.__typename === 'ConfigurableCartItem'
                          ? product.configured_variant.price_range.minimum_price.final_price.value
                          : product.prices.row_total.value
                      },
                      regular_price: {
                        value: product.__typename === 'ConfigurableCartItem'
                          ? product.configured_variant.price_range.minimum_price.regular_price.value
                          : product.prices.row_total.value
                      },
                    }
                  }
                }
              }
            },
            { discount: product.prices.total_item_discount.value.toString() },
            { quantity: 1 }
          ),
          {
            'goods_id': product.product.sku,
            'goods_price': product.prices.row_total.value,
          }
        );
      }
    }
  };

  const handleCoupon = async (couponCode = null, customQuery = null): Promise<void> => {
    const variables = {
      currentCart: cart.value,
      customQuery,
      couponCode,
    };

    const { updatedCart, errors } = couponCode
      ? await applyCouponCommand.execute(context, variables)
      : await removeCouponCommand.execute(context, variables);

    if (errors) {
      throw errors[0];
    }

    if (updatedCart) {
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    }
  };

  const applyCoupon = async ({ couponCode, customQuery }): Promise<void> => {
    Logger.debug('useCart.applyCoupon');

    try {
      loading.value = true;
      await handleCoupon(couponCode, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.applyCoupon = err;
      Logger.error('useCart/applyCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const removeCoupon = async ({ customQuery }): Promise<void> => {
    Logger.debug('useCart.removeCoupon');

    try {
      loading.value = true;
      await handleCoupon(null, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.removeCoupon = err;
      Logger.error('useCart/removeCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const canAddToCart = (product: Product, qty = 1) => {
    // eslint-disable-next-line no-underscore-dangle
    if (product?.__typename === 'ConfigurableProduct') {
      return !!product?.configurable_product_options_selection?.variant?.uid;
    }
    const inStockStatus = product?.stock_status || '';
    // const stockLeft = product?.only_x_left_in_stock === null //TODO: не понятно понадобится или нет в будущем, пока что не используем only_x_left_in_stock
    //   ? true
    //   : qty <= product?.only_x_left_in_stock;
    // return inStock && stockLeft;

    return Boolean(inStockStatus === ProductStockStatus.InStock);
  };

  return {
    setCart,
    cart,
    loadTotalQty,
    isInCart,
    addItem,
    load,
    removeItem,
    clear,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    canAddToCart,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useCart;
export * from './useCart';
