<template>
  <SfList class="social-links">
    <SfListItem
      class="social-links__item"
      v-for="social in socials"
      v-show="social.link"
      :key="social.title"
    >
      <SfLink
        v-if="social.link"
        class="social-links__link"
        :title="social.title"
        :link="social.link"
        target="_blank"
      >
        <SfIcon
          class="social-links__icon"
          :icon="social.icon"
          :label="social.title"
          style="--icon-size: 28px"
        />
      </SfLink>
    </SfListItem>
  </SfList>
</template>

<script>
import {
  defineComponent,
  computed
} from '@nuxtjs/composition-api';

import {
  SfList,
  SfLink,
  SfIcon
} from '~/components';

import { useConfigStore } from '~/stores/config';

export default defineComponent({
  name: 'SocialLinks',
  components: {
    SfList,
    SfLink,
    SfIcon
  },
  setup() {
    const {storeConfig} = useConfigStore();
    const websiteCode = computed(() => storeConfig.website_code);

    const websiteSpecifiedLinks = {
      togas_int_website: {
        facebook: 'https://www.facebook.com/togasofficial/',
        instagram: 'https://www.instagram.com/togasofficial/',
        youtube: 'https://www.youtube.com/channel/UCSwAK9wBYRBYSjFWsDgo8nw',
        telegram: null,
        whatsapp: null,
        pinterest: 'https://pin.it/ZXiUaBj',
      },
      togas_at_website: {
        facebook: 'https://www.facebook.com/togasofficial.at',
        instagram: 'https://www.instagram.com/togasofficial.at',
        telegram: 'https://t.me/togasaustria',
        whatsapp: 'https://wa.me/message/KZIAQGYDERWRJ1',
      }
    };

    const socials = computed(() => ([
      {
        title: 'Facebook',
        icon: 'social_facebook',
        link: websiteSpecifiedLinks[websiteCode.value]?.facebook
          || websiteSpecifiedLinks.togas_int_website.facebook,
      },
      {
        title: 'Instagram',
        icon: 'social_instagram',
        link: websiteSpecifiedLinks[websiteCode.value]?.instagram
          || websiteSpecifiedLinks.togas_int_website.instagram,
      },
      {
        title: 'Youtube',
        icon: 'social_youtube',
        link: websiteSpecifiedLinks[websiteCode.value]?.youtube
          || websiteSpecifiedLinks.togas_int_website.youtube,
      },
      {
        title: 'Pinterest',
        icon: 'social_pinterest',
        link: websiteSpecifiedLinks[websiteCode.value]?.pinterest
          || websiteSpecifiedLinks.togas_int_website.pinterest,
      },
      {
        title: 'Telegram',
        icon: 'social_telegram',
        link: websiteSpecifiedLinks[websiteCode.value]?.telegram
          || websiteSpecifiedLinks.togas_int_website.telegram,
      },
      {
        title: 'Whatsapp',
        icon: 'social_whatsapp',
        link: websiteSpecifiedLinks[websiteCode.value]?.whatsapp
          || websiteSpecifiedLinks.togas_int_website.whatsapp,
      }
    ]));

    return {
      socials
    };
  }
});
</script>
<style lang="scss" scoped>
.social-links {
  display: flex;
  gap: 20px;
  border-top: 1px solid var(--togas-grey-200-color);
  border-bottom: 1px solid var(--togas-grey-200-color);

  @include for-mobile {
    justify-content: center;
    padding: var(--spacer-sm) 0;
    margin-bottom: var(--spacer-base);
  }

  @include for-desktop {
    justify-content: center;
    padding: var(--spacer-base) 0;
    margin-left: -40px;
    margin-right: -40px;
  }

  &__link {
    fill: var(--togas-grey-100-color);
  }
}
</style>
