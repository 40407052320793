export const boutiqueQuery = `
query ($landingId: String!) {
  boutiqueLandings(filter: { landing_id: { eq: $landingId } }) {
    items {
      content
      landing_id
      name
      url_key
      media_gallery {
        asset_id
        desktop_url
        landing_id
        mobile_url
        role
        sort_order
        url
        label
      }
      boutiques {
        boutique_id
        business_hours_output
        is_active
        name
        thumbnail
        types
        contacts {
          type
          value
        }
        business_hours {
          closing_time
          opening_time
        }
        address {
          latitude
          longitude
          country_id
          region
          city
          street
          postcode
          latitude
          longitude
        }
      }
    }
  }
}

`;
