










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import LazyHydrate from 'vue-lazy-hydration';

import {
  ProductCare,
  ProductDescription,
  ProductExpandItem,
  ProductInfo,
  SfButton,
  SfCarousel,
  SfCarouselItem,
  SfHeading,
  SfLoader,
  SfReview
} from '~/components';

import {
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';

import { ProductPromoTypes } from '~/modules/catalog/product/constants';

export default defineComponent({
  name: 'SimpleProduct',
  components: {
    ProductCare,
    SfHeading,
    ProductInfo,
    SfLoader,
    SfCarousel,
    SfCarouselItem,
    SfReview,
    ProductDescription,
    SfButton,
    LazyHydrate,
    ProductExpandItem
  },
  props: {
    promoType: {
      type: String,
      default: '',
      required: true,
    }
  },
  emits: ['scroll-to-top'],
  setup(props, { emit }) {

    const carouselSettings = {
      peek: 0,
      perView: 3,
      dots: true,
      dotsClass: 'bullets-flat bullets-black',
      animationTimingFunc: 'ease-out',
      animationDuration: 330,
      slidePerPage: false,
      gap: 20,
      breakpoints:
        {
          1023:
            {
              peek: 0,
              perView: 1
            }
        }
    };

    const isBedlinePallada = computed(() => props.promoType === ProductPromoTypes.BEDLINE_PALLADA);
    const isPillowSovereign = computed(() => props.promoType === ProductPromoTypes.PILLOW_SOVEREIGN);
    const isDuvetInnotex = computed(() => props.promoType === ProductPromoTypes.DUVET_INNOTEX);
    const isPillowMinoris = computed(() => props.promoType === ProductPromoTypes.PILLOW_MINORIS);
    const isDuvetInfinity = computed(() => props.promoType === ProductPromoTypes.DUVET_INFINITY);
    const isPillowNobilis = computed(() => props.promoType === ProductPromoTypes.PILLOW_NOBILIS);
    const isPillowKaiser = computed(() => props.promoType === ProductPromoTypes.PILLOW_KAISER);
    const isPillowInfinity = computed(() => props.promoType === ProductPromoTypes.PILLOW_INFINITY);
    const isDuvetPalazzo = computed(() => props.promoType === ProductPromoTypes.DUVET_PALAZZO);
    const isPillowAnthraciteM = computed(() => props.promoType === ProductPromoTypes.PILLOW_ANTHRACITE_M);
    const isPillowAnthraciteL = computed(() => props.promoType === ProductPromoTypes.PILLOW_ANTHRACITE_L);
    const isPillowCuprum = computed(() => props.promoType === ProductPromoTypes.PILLOW_CUPRUM);
    const isPillowAquarius = computed(() => props.promoType === ProductPromoTypes.PILLOW_AQUARIUS);
    const isPillowSirius = computed(() => props.promoType === ProductPromoTypes.PILLOW_SIRIUS);
    const isPillowAtlantis = computed(() => props.promoType === ProductPromoTypes.PILLOW_ATLANTIS);
    const isPillowElement = computed(() => props.promoType === ProductPromoTypes.PILLOW_ELEMENT);

    const handleScrollTop = () => {
      emit('scroll-to-top');
    };

    return {
      carouselSettings,
      handleScrollTop,
      isBedlinePallada,
      isPillowSovereign,
      isDuvetInnotex,
      isPillowMinoris,
      isDuvetInfinity,
      isPillowNobilis,
      isPillowKaiser,
      isPillowInfinity,
      isDuvetPalazzo,
      isPillowAnthraciteM,
      isPillowAnthraciteL,
      isPillowCuprum,
      isPillowAquarius,
      isPillowSirius,
      isPillowAtlantis,
      isPillowElement
    };
  },
});
