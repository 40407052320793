import { ref, useContext } from "@nuxtjs/composition-api";
import { useApi } from "~/composables/";
import { boutiqueQuery } from '../../queries/getBoutique';

export function useBoutiques() {
  const { app } = useContext();
  const { query } = useApi();
  const loadingBoutiques = ref(false);
  const loadingBoutique = ref(false);

  const loadBoutiques = async () => {
    let result = ref();

    try {
      loadingBoutiques.value = true;
      // @ts-ignore
      result.value = await app.$vsf.$magento.api.getBoutiques();
    } catch (error) {
      console.warn('Error: ', error);
    } finally {
      loadingBoutiques.value = false;
    }

    return result;
  };

  const loadBoutique = async (landingId) => {
    let result = ref();

    try {
      loadingBoutique.value = true;
      result.value = await query(boutiqueQuery, { landingId });
    } catch (error) {
      console.warn('Error: ', error);
    } finally {
      loadingBoutique.value = false;
    }

    return result;
  };

  return {
    loadBoutiques,
    loadingBoutiques,
    loadBoutique,
    loadingBoutique,
  }
}

export default useBoutiques;
