<template>
  <div class="subscribe col-12 col-m-3">
    <div class="subscribe__container"
         v-click-outside="resetValidation"
    >
      <SfHeading
        class="subscribe__title sf-heading--left"
        :title="$tc(headingText)"
        :level="2"
        :styleLevel="1"
      />
      <p class="subscribe__subtitle mobile-only">
        {{ $i18n.t('Be aware of upcoming sales and events. Receive gifts and special offers!') }}
      </p>
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <form
          id="subscribe-form"
          class="subscribe__form"
          @submit.prevent="handleSubmit(submitForm(reset, form.email)); clearForm()"
        >
          <div class="subscribe__input-wrapper">
            <ValidationProvider
              class="subscribe__input-container"
              v-slot="{ errors }"
              rules="required|email"
              name="email"
            >
              <SfInput
                v-model.trim="form.email"
                id="subscribe-form-email"
                class="subscribe__input"
                name="subscribe-email"
                :placeholder="$t('Enter your email address')"
                :valid="!errors[0]"
                :error-message="$t(errors[0])"
              />
            </ValidationProvider>
            <SfButton
              class="subscribe__button"
              type="submit"
              aria-label="subscribe to newsletter"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <g clip-path="url(#clip0_4324_3799)">
                  <path
                    d="M5.58406 0.268337C5.50976 0.190676 5.40818 0.162614 5.27981 0.296795C5.23207 0.340433 5.09891 0.505745 5.02584 0.59244C4.90195 0.73945 4.91909 0.81165 5.08943 0.989701C5.25976 1.16775 13.7289 9.19429 13.9196 9.3937C14.1104 9.59312 14.0469 9.75459 13.9196 9.88758C13.7924 10.0206 5.29312 18.2081 5.09413 18.4161C4.89515 18.6241 4.91447 18.7599 5.02779 18.8784L5.29312 19.1557C5.38157 19.2482 5.54464 19.1991 5.67454 19.0633C5.80444 18.9275 15.0635 10.0407 15.21 9.88758C15.3564 9.73446 15.3205 9.61022 15.21 9.49466C15.0994 9.3791 5.65835 0.345999 5.58406 0.268337Z"
                    fill="currentColor" />
                </g>
                <defs>
                  <clipPath id="clip0_4324_3799">
                    <rect width="19" height="19" fill="white" transform="translate(0.625 0.203125)" />
                  </clipPath>
                </defs>
              </svg>
            </SfButton>
          </div>
          <ValidationProvider
            class="subscribe__checkbox-container"
            v-slot="{ errors }"
            :rules="{ required: { allowFalse: false } }"
          >
            <SfCheckbox
              id="subscribe-form-checkbox"
              class="subscribe-checkbox"
              name="agreement"
              v-model="form.agreement"
              :valid="!errors[0]"
              :error-message="$t(errors[0])"
              :label="$t('By opting in I agree to Togas`s terms and conditions and understand I can opt out at any time')"
            />
          </ValidationProvider>
          <recaptcha v-if="isRecaptchaEnabled" />
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  ref,
  useContext
} from '@nuxtjs/composition-api';

import {
  SfButton,
  SfCheckbox,
  SfInput,
  SfNotification,
  SfHeading,
  SfIcon
} from '~/components';

import {
  useApi,
  useUiNotification,
  useWebsiteCode
} from '~/composables';

import { clickOutside } from '~/utilities/directives';

import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  extend
} from 'vee-validate';

import {
  email,
  required
} from 'vee-validate/dist/rules';


setInteractionMode('eager');
extend('required', {
  ...required,
  message: 'This field is required',
});
extend('email', {
  ...email,
  message: 'Enter a correct email',
});

export default defineComponent({
  name: 'SubscribeForm',
  components: {
    SfInput,
    SfButton,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
    SfNotification,
    SfHeading,
    SfIcon
  },
  directives: {clickOutside},
  data() {
    return {
      subscribeSent: false,
      form: {
        email: '',
        agreement: false
      }
    };
  },
  setup() {
    const {query} = useApi();
    const {app: {i18n}} = useContext();
    const {send: sendNotification} = useUiNotification();
    const {isInt, isAu} = useWebsiteCode();

    const headingText = computed(() => {

      if(isInt) {
        return 'Subscribe today to receive 10% OFF';
      }

      if(isAu) {
        return 'Subscribe today.';
      }
    });

    const {$recaptcha, $config} = useContext();
    const isRecaptchaEnabled = ref(
      typeof $recaptcha !== 'undefined' && $config.isRecaptcha,
    );
    let recaptchaToken = '';

    let subscribeSent = ref(false);
    const icon = 'M5.58424 0.221523C5.50994 0.143862 5.40836 0.1158 5.27999 0.249981C5.23225 0.293619 5.09909 0.458931 5.02603 0.545626C4.90213 0.692636 4.91928 0.764836 5.08961 0.942887C5.25995 1.12094 13.729 9.14747 13.9198 9.34689C14.1106 9.54631 14.047 9.70778 13.9198 9.84076C13.7926 9.97375 5.2933 18.1613 5.09432 18.3693C4.89533 18.5773 4.91465 18.7131 5.02797 18.8316L5.2933 19.1089C5.38175 19.2014 5.54482 19.1522 5.67472 19.0165C5.80462 18.8807 15.0637 9.99388 15.2101 9.84076C15.3566 9.68764 15.3207 9.56341 15.2101 9.44785C15.0996 9.33228 5.65854 0.299185 5.58424 0.221523Z';
    const submitForm = (reset, email) => async () => {
      reset();

      try {
        if(isRecaptchaEnabled.value) {
          $recaptcha.init();
          recaptchaToken = await $recaptcha.getResponse();
        }

        const {data, errors} = await query(`
          mutation {
            subscribeEmailToNewsletter(
              email: "${email}"
            ) {
              status
            }
          }
        `);

        if(data.subscribeEmailToNewsletter?.status === 'NOT_ACTIVE') {
          sendNotification({
            id: Symbol('subscribe_not_active'),
            message: i18n.tc('You have successfully signed up for our newsletter.'),
            type: 'success',
            icon: 'check',
            persist: false,
          });
        } else {
          sendNotification({
            id: Symbol('subscribe_error'),
            message: errors[0].message,
            type: 'info',
            icon: 'check',
            persist: false,
          });
        }

        if(isRecaptchaEnabled.value) {
          $recaptcha.reset();
        }

        subscribeSent = ref(true);
      }
      catch (error) {
        console.error('Error: ', error);
      }
    };

    return {
      subscribeSent,
      submitForm,
      isRecaptchaEnabled,
      icon,
      headingText
    };
  },
  methods: {
    clearForm() {
      if(!this.subscribeSent) {
        this.form.email = '';
        this.form.agreement = false;
        this.subscribeSent = false;
      }
    },
    resetValidation() {
      this.$refs.form.reset();
    }
  }
});
</script>

<style lang="scss" scoped>
.subscribe {

  @include for-desktop {
    order: 1;
    --heading-title-font-family: var(--font-family--secondary);
    --heading-title-font-weight: var(--font-weight--normal);
    --heading-title-font-size: var(--font-size--base);
    --heading-title-font-line-height: var(--line-height--base);
  }

  &__container {
    overflow: auto;
    position: relative;
    padding: var(--spacer-lg) 0 var(--spacer-lg) var(--spacer-sm);

    @include for-mobile {
      background: url("~assets/images/subscribe/pattern.webp") no-repeat;
      background-size: cover;
      background-position: center;
    }

    @include for-desktop {
      padding: 0;
      overflow: hidden;
    }
  }

  &__title {
    @include for-desktop {
      --heading-margin: 0;
      --heading-padding: var(--spacer-sm) 0 0;
      --heading-color: var(--togas-grey-100-color);
    }
  }

  &__subtitle {
    font-size: var(--font-size--lg);
    line-height: var(--line-height--lg);
    color: var(--togas-grey-800-color);
    margin: 0 0 var(--spacer-base) 0;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--spacer-xs) var(--spacer-2xs);

    @include for-desktop {
      position: relative;
    }

    &--flex-start {
      align-items: flex-start;
    }
  }

  &__input-wrapper {
    display: flex;
    flex-basis: 100%;

    .sf-input__error-message {
      --input-error-message-font-size: var(--font-size--sm);
    }
  }

  &__input-container {
    width: 100%;
    min-height: 40px;
  }

  &__input {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-2xs);
    margin: var(--spacer-2xs) 0 0 0;
  }

  &__checkbox-container {
    width: 100%;
  }

  &__button {
    background: none;
    padding: 0;
    align-items: center;
    justify-content: center;
    --button-size: 48px;
    --button-color: var(--togas-black-color);

    &:before {
      box-shadow: none;
    }

    svg {
      border: 1px solid var(--togas-grey-800-color);
      margin: 0 9px;
      padding: 4px;

      @include for-desktop {
        border: none;

        path {
          fill: var(--togas-grey-100-color);
        }
      }
    }
  }

  &__button-icon {
    --button-size: 48px;
    min-width: 30px;
    min-height: 30px;
    border: 1px solid var(--togas-grey-800-color);
  }

  ::v-deep {
    .sf-input {
      --input-border: solid black;
      --input-border-width: 1px;

      @include for-desktop {
        --input-border: solid var(--togas-grey-400-color);
        --input-border-width: 0 0 1px 0;
        --input-padding: var(--spacer-xs) var(--spacer-sm) var(--spacer-xs) 0;
        --input-color: white;
      }

      input {
        @include for-mobile {
          &::placeholder {
            color: transparent;
          }
        }

        @include for-desktop {
          &::placeholder {
            font-size: var(--font-size--xs);
            line-height: var(--font-size--lg);
            color: var(--togas-grey-400-color);
          }
        }

        &:focus-visible {
          outline: none;
        }
      }
    }

    .sf-input__wrapper {
      --input-margin: 0;
    }

    .sf-checkbox {
      padding: 0;
    }

    .sf-checkbox__container {
      align-items: flex-start;
      margin: 0;
    }

    .sf-checkbox__label {
      font-size: var(--font-size--sm);
      line-height: var(--line-height--sm);

      @include for-desktop {
        color: var(--togas-grey-400-color);
        font-size: var(--font-size--xs);
        line-height: var(--line-height--xxs);
      }
    }

    .sf-checkbox__checkmark.is-active {

      @include for-desktop {
        background-color: var(--togas-grey-800-color);
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.66699 5.39875L4.861 8.69783L12.1401 1' stroke='%23E6E6E5' stroke-linecap='square'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
</style>
