import type { Price, MediaGalleryItem, MediaGalleryVideoItem } from '~/modules/catalog/types';
import type { Product, ProductAttribute, PromoDetailsImage } from '~/modules/catalog/product/types';
import { Breadcrumb } from '~/modules/catalog/types';

import type {
  BundleProduct,
  CategoryInterface,
  GroupedProduct,
  ProductInterface,
  CategoryTree,
  TogasProductDetails,
} from '~/modules/GraphQL/types';

import { htmlDecode } from '~/helpers/htmlDecoder';
import { getTotalReviews, getAverageRating } from '~/modules/review/getters/reviewGetters';
import { ProductPromoTypes } from '~/modules/catalog/product/constants';

export interface ProductGetters {
  getName: (product: ProductInterface) => string;
  getPrice: (product: ProductInterface) => Price;
  getGallery: (product: ProductInterface, maxGallerySize: number) => MediaGalleryItem[];
  getVideoGallery: (product: ProductInterface) => MediaGalleryVideoItem;
  getCoverImage: (product: ProductInterface) => string;
  getAttributes: (products: ProductInterface[] | ProductInterface, filters?: Array<string>) => Record<string, ProductAttribute | string>;
  getDescription: (product: ProductInterface) => string;
  getCategoryIds: (product: ProductInterface) => string[];
  getId: (product: ProductInterface) => string;
  getTotalReviews: (product: ProductInterface) => number;
  getAverageRating: (product: ProductInterface) => number;
  getBreadcrumbs?: (product: ProductInterface, category?: CategoryInterface) => Breadcrumb[];

  getSlug(product: ProductInterface, category?: CategoryInterface): string;

  getCategory(product: Product, currentUrlPath: string): CategoryTree | null;

  getProductRelatedProduct(product: ProductInterface): Product[];

  getProductSku(product: ProductInterface): string;

  getProductThumbnailImage(product: ProductInterface): string;

  getProductUpsellProduct(product: ProductInterface): ProductInterface[];

  getShortDescription(product: ProductInterface): string;

  getTypeId(product: ProductInterface): string;

  getSwatchData(swatchData: Product['configurable_options'][0]['values'][0]['swatch_data']): string | undefined;

  getGroupedProducts(product: GroupedProduct): GroupedProduct['items'] | undefined;

  getBundleProducts(product: BundleProduct): BundleProduct['items'] | undefined;

  getPromoDetailsImage: (product: ProductInterface) => PromoDetailsImage;

  [getterName: string]: any;
}

export const getName = (product: ProductInterface): string => {
  if (!product) {
    return '';
  }

  return htmlDecode(product.name);
};

export const getSlug = (product: ProductInterface, category?: CategoryTree | CategoryInterface): string => {
  const rewrites = product?.url_rewrites;
  let url = product?.sku ? `/p/${product.sku}` : '';
  if (!rewrites || rewrites.length === 0) {
    return url;
  }

  url = `/${rewrites[0].url}`;
  // eslint-disable-next-line no-restricted-syntax
  for (const rewrite of rewrites) {
    if (category && category.uid) {
      url = `/${rewrite.url}`;
      break;
    }
  }

  return url;
};

export const getPrice = (product: ProductInterface): Price => {
  let regular = null;
  let special = null;
  let maximum = null;
  let final = null;

  if (product?.configurable_product_options_selection?.variant?.price_range) {
    const path = product?.configurable_product_options_selection?.variant?.price_range;
    regular = path.minimum_price.regular_price.value;
    final = path.minimum_price.final_price.value;
    maximum = path.maximum_price.final_price.value;

    if (final < regular) {
      special = final;
    }
  } else if (product?.price_range) {
    regular = product.price_range.minimum_price.regular_price.value;
    final = product.price_range.minimum_price.final_price.value;
    maximum = product.price_range.maximum_price.final_price.value;

    if (final < regular) {
      special = final;
    }
  }

  return {
    regular,
    special,
    maximum,
    final,
  };
};

export const getIsCustomGallery = (product: Product): boolean => product?.custom_gallery === 1;

export const getGallery = (product: Product, maxGallerySize = 14): MediaGalleryItem[] => {
  const images = [];
  if (!product?.media_gallery?.length && !product?.configurable_product_options_selection?.media_gallery?.length) {
    return images;
  }

  // eslint-disable-next-line no-restricted-syntax
  if (product?.media_gallery?.length) {
    for (const galleryItem of product.media_gallery) {
      if (galleryItem.__typename === 'ProductImage') {
        images.push({
          source_link: galleryItem.source_link,
          desktop: galleryItem.desktop,
          mobile: galleryItem.mobile,
          roles: galleryItem.roles,
        });
      }
    }
  }

  return images.slice(0, maxGallerySize);
};

export const getVideoGallery = (product: Product): MediaGalleryVideoItem => {
  const video = {
    preview: null,
    video_content: null
  };

  if (!product?.media_gallery?.length && !product?.configurable_product_options_selection?.media_gallery?.length) {
    return video;
  }


  // eslint-disable-next-line no-restricted-syntax
  if (product?.media_gallery?.length) {
    for (const galleryItem of product.media_gallery) {
      if (galleryItem.__typename === 'ProductVideo') {
        video.preview = galleryItem.mobile;
        video.video_content = galleryItem.video_content;
      }
    }
  }
  return video;
};

export const getCoverImage = (product: Product): string => {
  if (!product || !product.image) {
    return null;
  }

  return product.image.url;
};

export const getProductThumbnailImage = (product: Product): string => {
  if (!product || !product.thumbnail) {
    return null;
  }

  return product.thumbnail.url;
};

export const getProductSmallImage = (product: Product): string => {
  if (!product || !product.small_base_image) {
    return null;
  }

  return product.small_base_image;
};

export const getOptimizedProductThumbnailImage = (product: Product): string => {
  if (!product || !product.product_thumbnail) {
    return null;
  }

  return product.product_thumbnail;
};

export const getProductHoverImage = (product: Product): string => {
  if (!product || !product.product_hover_image) {
    return null;
  }

  return product.product_hover_image;
};

export const getAttributes = (
  products: Product,
  _filterByAttributeName?: string[],
): Record<string, ProductAttribute | string> => {
  if (!products || !products?.configurable_options) {
    return {};
  }

  const attributes = {};
  const configurableOptions = products.configurable_options;

  // eslint-disable-next-line no-restricted-syntax
  for (const option of configurableOptions) {
    attributes[option.attribute_code] = {
      code: option.attribute_code,
      label: option.label,
      value: option.values.map((value) => {
        const obj = {};
        obj[value.value_index] = value.label;
        return obj;
      }),
    } as ProductAttribute;
  }
  return attributes;
};

export const getDescription = (product: Product): string => {
  if (!product || !product?.description) {
    return '';
  }

  return product.description.html;
};

export const getHeadDescription = (product: Product): string => {
  if (!product || !product?.head_description) {
    return '';
  }

  return product.head_description;
};

export const getShortDescription = (product: Product): string => {
  if (!product || !product.short_description) {
    return '';
  }
  return product.short_description.html;
};

export const getDetails = (product: Product): TogasProductDetails[] => {
  if (!product || !product.details) {
    return [];
  }
  return product.details;
};

export const getCare = (product: Product): string => {
  if (!product || !product?.care) {
    return '';
  }

  return product.care.html;
};

export const getIsSensotex = (product: Product): Boolean => {
  if (!product || !product?.togas_fabric) {
    return false;
  }
  return Boolean(product.togas_fabric.some((item) => item.includes('SENSOTEX')));
};

export const hasCategoryCollectionLink = (product: Product): Boolean => {
  return !!(product && product?.category_collection_link && product?.category_collection_link?.url_path);
};

export const getCategoryCollectionLabel = (product: Product): String => {
  if (!product || !product?.category_collection_link || !product?.category_collection_link.label) return null;

  return product.category_collection_link.label;
};

export const getCategoryCollectionUrl = (product: Product): String => {
  if (!product || !product?.category_collection_link || !product?.category_collection_link.url_path) return null;

  return `/${product.category_collection_link.url_path}.html`;
};

export const getCategoryCollectionIconUrl = (product: Product): String => {
  if (!product || !product?.category_collection_link || !product?.category_collection_link.icon_url) return null;

  return product.category_collection_link.icon_url;
};

export const getBadge = (product: Product): Array<any> => {
  if (!product || !product.badge?.length) {
    return [];
  }
  return product.badge;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCategoryIds = (product: Product): string[] => {
  const categoryIds = [];

  if (!product?.categories) {
    return categoryIds;
  }

  return product.categories.map((category) => category.uid);
};

export const getCategory = (product: any, currentUrlPath: string): CategoryTree | null => {
  if (!product?.categories || product?.categories.length === 0) {
    return null;
  }

  const categories = currentUrlPath.split('/');
  categories.pop();

  if (categories.length === 0) {
    return null;
  }

  const categoryPath = categories.join('/');

  // eslint-disable-next-line no-restricted-syntax
  for (const category of product.categories) {
    if (`/${category.url_path}` === categoryPath) {
      return category;
    }
  }

  return null;
};

export const getId = (product: Product): string => product.uid;

export const getProductSku = (product: Product): string => product.sku;

// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
export const getTypeId = (product: Product): string => product.__typename;

const getCategoryBreadcrumbs = (category: CategoryInterface): Breadcrumb[] => {
  let breadcrumbs = [];

  if (!category) {
    return [];
  }

  if (Array.isArray(category?.breadcrumbs)) {
    breadcrumbs = category.breadcrumbs.map((breadcrumb) => ({
      text: breadcrumb.category_name,
      link: `/${breadcrumb.category_url_path}${category.url_suffix || ''}`,
    } as Breadcrumb));
  }

  breadcrumbs.push({
    text: category.name,
    link: `/${category.url_path}${category.url_suffix || ''}`,
  } as Breadcrumb);

  return breadcrumbs;
};

export const getBreadcrumbs = (product: ProductInterface, category?: CategoryInterface): Breadcrumb[] => {
  let breadcrumbs = [];

  if (!product) {
    return breadcrumbs;
  }

  if (category) {
    breadcrumbs = getCategoryBreadcrumbs(category) as Breadcrumb[];
  }

  breadcrumbs.push({
    text: getName(product),
    link: `/${product?.url_rewrites?.[0]?.url ?? product.url_key}`,
  });

  return breadcrumbs;
};

export { getTotalReviews, getAverageRating } from '~/modules/review/getters/reviewGetters';

export const getProductRelatedProduct = (product: any): Product[] => product?.related_products || [];

export const getProductUpsellProduct = (product: any): Product[] => product?.upsell_products || [];

export const getSwatchData = (swatchData: Product['configurable_options'][0]['values'][0]['swatch_data']): string | undefined => swatchData?.value;

export const getTagLine = (product: Product): string => {
  if (!product || !product?.tag_line) {
    return '';
  }

  return product.tag_line;
};

export const getIsNotifyEnable = (product: Product): Boolean => {
  if (!product || !product?.is_notify_me_enabled) {
    return false;
  }

  return product.is_notify_me_enabled;
};

export const getIsSaleable = (product: Product, productOptions, activeSize): Boolean => {
  if (!product || (product.__typename === 'SimpleProduct' && !product?.saleable_qty)) {
    return false;
  }

  if (product.__typename === 'ConfigurableProduct') {
    if (productOptions) {
      const selectedOption = productOptions[0]?.values.find((size) => size.uid === activeSize);
      return selectedOption ? Boolean(selectedOption.saleable_qty) : false;
    }
  }
  return Boolean(product.saleable_qty);
};

export const getIsInStock = (product: Product): Boolean => {
  return (product && (product.stock_status === 'IN_STOCK'));
};

export const getIsHasSizeTable = (product: Product): Boolean => Boolean(product.has_size_guide_table ? product.has_size_guide_table : false);

export const getIsPresentation = (product: Product): Boolean => true;

export const getIsPromo = (product: Product): Boolean => {
  if (!product || !product.product_promo_block_type) {
    return false;
  }

  if (
    product.product_promo_block_type === ProductPromoTypes.BEDLINE_PALLADA ||
    product.product_promo_block_type === ProductPromoTypes.PILLOW_SOVEREIGN ||
    product.product_promo_block_type === ProductPromoTypes.DUVET_INNOTEX ||
    product.product_promo_block_type === ProductPromoTypes.PILLOW_MINORIS ||
    product.product_promo_block_type === ProductPromoTypes.DUVET_INFINITY ||
    product.product_promo_block_type === ProductPromoTypes.PILLOW_NOBILIS ||
    product.product_promo_block_type === ProductPromoTypes.PILLOW_KAISER ||
    product.product_promo_block_type === ProductPromoTypes.PILLOW_INFINITY ||
    product.product_promo_block_type === ProductPromoTypes.DUVET_PALAZZO ||
    product.product_promo_block_type === ProductPromoTypes.PILLOW_ANTHRACITE_M ||
    product.product_promo_block_type === ProductPromoTypes.PILLOW_ANTHRACITE_L ||
    product.product_promo_block_type === ProductPromoTypes.PILLOW_CUPRUM ||
    product.product_promo_block_type === ProductPromoTypes.PILLOW_AQUARIUS ||
    product.product_promo_block_type === ProductPromoTypes.PILLOW_SIRIUS ||
    product.product_promo_block_type === ProductPromoTypes.PILLOW_ATLANTIS ||
    product.product_promo_block_type === ProductPromoTypes.PILLOW_ELEMENT
  ) {
    return true;
  }
};

export const getPromoDetailsImage = (product: Product): PromoDetailsImage => {
  if (!product || !product.product_promo_block_type) {
    return null;
  }

  if (
    product.product_promo_block_type === ProductPromoTypes.PILLOW_SOVEREIGN
  ) {
    return {
      desktop: `sovereign-pillow/details-product.webp`,
      mobile: `sovereign-pillow/details-product-mob.webp`,
    };
  }

  if (
    product.product_promo_block_type === ProductPromoTypes.DUVET_INNOTEX
  ) {
    return {
      desktop: `innotex-duvet/details-product.webp`,
      mobile: `innotex-duvet/details-product-mob.webp`,
    };
  }

  if (
    product.product_promo_block_type === ProductPromoTypes.PILLOW_MINORIS
  ) {
    return {
      desktop: `minoris-pillow/details-product.webp`,
      mobile: `minoris-pillow/details-product-mob.webp`,
    };
  }

  if (
    product.product_promo_block_type === ProductPromoTypes.DUVET_INFINITY
  ) {
    return {
      desktop: `infinity-duvet/details-product.webp`,
      mobile: `infinity-duvet/details-product-mob.webp`,
    };
  }

  if (
    product.product_promo_block_type === ProductPromoTypes.PILLOW_NOBILIS
  ) {
    return {
      desktop: `nobilis-pillow/details-product.webp`,
      mobile: `nobilis-pillow/details-product-mob.webp`,
    };
  }

  if (
    product.product_promo_block_type === ProductPromoTypes.PILLOW_KAISER
  ) {
    return {
      desktop: `kaiser-pillow/details-product.webp`,
      mobile: `kaiser-pillow/details-product-mob.webp`,
    };
  }

  if (
    product.product_promo_block_type === ProductPromoTypes.PILLOW_INFINITY
  ) {
    return {
      desktop: `infinity-pillow/details-product.webp`,
      mobile: `infinity-pillow/details-product-mob.webp`,
    };
  }

  if (
    product.product_promo_block_type === ProductPromoTypes.DUVET_PALAZZO
  ) {
    return {
      desktop: `palazzo-duvet/details-product.webp`,
      mobile: `palazzo-duvet/details-product-mob.webp`,
    };
  }

  if (
    product.product_promo_block_type === ProductPromoTypes.PILLOW_ANTHRACITE_M ||
    product.product_promo_block_type === ProductPromoTypes.PILLOW_ANTHRACITE_L
  ) {
    return {
      desktop: `anthracite-pillow/details-product.webp`,
      mobile: `anthracite-pillow/details-product-mob.webp`,
    };
  }

  if (
    product.product_promo_block_type === ProductPromoTypes.PILLOW_CUPRUM
  ) {
    return {
      desktop: `cuprum-pillow/details-product.webp`,
      mobile: `cuprum-pillow/details-product-mob.webp`,
    };
  }

  if (
    product.product_promo_block_type === ProductPromoTypes.PILLOW_AQUARIUS
  ) {
    return {
      desktop: `aquarius-pillow/details-product.webp`,
      mobile: `aquarius-pillow/details-product-mob.webp`,
    };
  }

  if (
    product.product_promo_block_type === ProductPromoTypes.PILLOW_SIRIUS
  ) {
    return {
      desktop: `sirius-pillow/details-product.webp`,
      mobile: `sirius-pillow/details-product-mob.webp`,
    };
  }

  if (
    product.product_promo_block_type === ProductPromoTypes.PILLOW_ATLANTIS
  ) {
    return {
      desktop: `atlantis-pillow/details-product.webp`,
      mobile: `atlantis-pillow/details-product-mob.webp`,
    };
  }

  if (
    product.product_promo_block_type === ProductPromoTypes.PILLOW_ELEMENT
  ) {
    return {
      desktop: `element-pillow/details-product.webp`,
      mobile: `element-pillow/details-product-mob.webp`,
    };
  }
};

const sortProduct = (a, b) => a.position - b.position;

// eslint-disable-next-line no-underscore-dangle
export const getGroupedProducts = (product: GroupedProduct & {
  __typename: string
}): GroupedProduct['items'] | undefined => product.__typename === 'GroupedProduct' && product?.items?.sort(sortProduct);

// eslint-disable-next-line no-underscore-dangle
export const getBundleProducts = (product: BundleProduct & {
  __typename: string
}): BundleProduct['items'] | undefined => product.__typename === 'BundleProduct' && product?.items?.sort(sortProduct);

const productGetters: ProductGetters = {
  getAttributes,
  getAverageRating,
  getBreadcrumbs,
  getCategory,
  getCategoryIds,
  getCoverImage,
  getDescription,
  getHeadDescription,
  getIsCustomGallery,
  getGallery,
  getVideoGallery,
  getId,
  getName,
  getBadge,
  getPrice,
  getProductRelatedProduct,
  getProductSku,
  getProductThumbnailImage,
  getProductSmallImage,
  getOptimizedProductThumbnailImage,
  getProductHoverImage,
  getProductUpsellProduct,
  getShortDescription,
  getDetails,
  getCare,
  getIsSensotex,
  getSlug,
  getTotalReviews,
  getTypeId,
  getSwatchData,
  getGroupedProducts,
  getBundleProducts,
  getTagLine,
  hasCategoryCollectionLink,
  getCategoryCollectionLabel,
  getCategoryCollectionUrl,
  getCategoryCollectionIconUrl,
  getIsNotifyEnable,
  getIsSaleable,
  getIsInStock,
  getIsHasSizeTable,
  getIsPresentation,
  getIsPromo,
  getPromoDetailsImage
};

export default productGetters;
