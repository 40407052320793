<template>
  <div class="product--description">
    <p>{{ headDescriptionParts.firstPart }}</p>
    <p v-if=showFullDescription>{{ headDescriptionParts.secondPart }}</p>
    <button v-if="isSecondPart" @click="toggleDescription">{{ $t(toggleButtonText) }}</button>

    <div class="product--details">
      <div v-for="detail in details"
           :key="detail.label"
           class="product--details__item"
      >
        <span class="product--details__item-label">{{ detail.label }}:</span>
        <span class="product--details__item-value">{{ detail.value }}</span>
      </div>
    </div>
    <span
      v-if="sku"
      class="product--sku__hidden"
    >{{ sku }}</span>
  </div>
</template>

<script>
export default {
  props: {
    headDescription: {
      type: String,
      required: true,
      default: '',

    },
    details: {
      type: Array || null,
      required: true,
      default: () => [],
    },
    sku: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      showFullDescription: false,
    };
  },
  computed: {
    toggleButtonText() {
      return this.showFullDescription ? 'Show less' : 'Show more';
    },
    isSecondPart() {
      return Boolean(this.headDescriptionParts?.secondPart?.length);
    },
    headDescriptionParts() {
      if(!this.headDescription) return false;
      const sentences = [];
      const pattern = /[^.!?]*[.!?]/u;
      const maximumLength = 200;
      const headLength = this.headDescription.length;
      let firstPart = '';
      let secondPart = '';

      if(headLength > maximumLength) {
        const matchResult = this.headDescription.match(pattern);
        if(matchResult) {
          const firstSentence = matchResult[0];
          const secondSentence = matchResult[1] || '';
          const combinedLength = firstSentence.length + secondSentence.length;

          if(combinedLength > maximumLength) {
            firstPart = firstSentence;
          } else {
            firstPart = firstSentence + secondSentence;
            secondPart = this.headDescription.substring(firstPart.length);
          }
        }
      } else {
        firstPart = this.headDescription;
      }

      return {
        firstPart,
        secondPart
      };
    }
  },
  methods: {
    toggleDescription() {
      this.showFullDescription = !this.showFullDescription;
    }
  }
};
</script>

<style lang="scss" scoped>
.product {

  &--description {
    p {
      margin: 0;
    }

    p + p {
      margin-top: var(--spacer-sm);
    }

    button {
      color: var(--togas-grey-color);
      background: none;
      border: none;
      border-bottom: 1px solid var(--togas-grey-color);
      margin: var(--spacer-sm) 0 0 0;
      padding: 0;
      cursor: pointer;
    }
  }

  &--details {
    margin-top: var(--spacer-m);

    @include for-desktop {
      margin-top: var(--spacer-base);
    }

    &__item {
      display: flex;
      gap: var(--spacer-xs);
      padding: var(--spacer-sm) 0;
      border-bottom: 1px solid var(--togas-grey-200-color);

      @include for-desktop {
        gap: var(--spacer-sm);
      }

      @include for-desktop($desktop-xl-min) {
        gap: var(--spacer-xl);
      }

      &-label {
        min-width: 120px;

        @include for-desktop {
          max-width: 70px;
        }

        @include for-desktop($desktop-xl-min) {
          max-width: 120px;
        }
      }
    }
  }

  &--sku__hidden {
    color: var(--togas-white-color);
    font-size: 8px;
    line-height: 8px;
    position: absolute;
    margin-top: 8px;
  }
}
</style>
