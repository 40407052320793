import {
  computed,
  readonly,
  Ref
} from '@nuxtjs/composition-api';

import { useConfigStore } from '~/stores/config';

import {
  AT_WEBSITE_CODE,
  INT_WEBSITE_CODE,
  ME_WEBSITE_CODE
} from '~/constants';

export function useWebsiteCode(): {
  isInt: Readonly<Ref<boolean>>,
  isAu: Readonly<Ref<boolean>>
  isMe: Readonly<Ref<boolean>>
} {
  const { storeConfig } = useConfigStore();

  const isInt = readonly(computed(() => storeConfig.website_code === INT_WEBSITE_CODE));
  const isAu = readonly(computed(() => storeConfig.website_code === AT_WEBSITE_CODE));
  const isMe = readonly(computed(() => storeConfig.website_code === ME_WEBSITE_CODE));

  return {
    isInt,
    isAu,
    isMe
  };
}

export * from './useWebsiteCode';
export default useWebsiteCode;
