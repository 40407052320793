<template functional>
  <div
    :class="[data.class, data.staticClass, 'sf-heading']"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot name="title" v-bind="{ props }">
      <component
        :is="`h${props.level}`"
        class="sf-heading__title"
        :class="props.styleLevel > 1 && $options.headingClass(props.styleLevel)"
      >{{ props.title }}</component>
    </slot>
    <slot name="description" v-bind="{ props }">
      <div
        :class="{
          'display-none': !$options.hasDescription(props.description, slots),
        }"
        class="sf-heading__description"
        v-html="props.description"
      ></div>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'SfHeading',
  props: {
    level: {
      type: Number,
      default: 2,
    },
    styleLevel: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  hasDescription(descriptionProp, slots) {
    return !!descriptionProp || slots().description;
  },
  headingClass(styleLevel) {
    return styleLevel >= 1 && styleLevel <= 6 ? `sf-heading__title--${styleLevel}` : '';
  },
};
</script>
<style lang="scss">
@import "~@/styles/components/atoms/SfHeading";
</style>
