<template>
  <div
    class="sf-product-card-horizontal"
    :class="{ 'has-colors': colors.length }"
    data-testid="product-card-horizontal"
    :data-sku="sku"
  >
    <template v-if="isProductPresentation">
      <SfButton
        :link="link"
        class="sf-product-card-horizontal__presentation-wrapper sf-button--pure sf-product-card-horizontal__link"
        data-testid="product-link"
        aria-label="Go To Product"
        v-on="$listeners"
      >
        <template v-if="Array.isArray(image)">
          <SfImage
            v-for="(picture, key) in image.slice(0, 2)"
            :key="key"
            class="sf-product-card-horizontal__picture"
            :class="key !== 0 ? 'sf-product-card-horizontal__image-hover' : 'sf-product-card-horizontal__image-main'"
            :src="picture"
            :placeholder="placeholder"
            :alt="alt"
            :width="imageWidth"
            :height="imageHeight"
          />
        </template>
        <SfImage
          v-else
          class="sf-product-card-horizontal__image"
          :src="image"
          :alt="alt"
          :width="imageWidth"
          :height="imageHeight"
        />
        <div class="sf-product-card-horizontal__wrapper--info">
          <span class="sf-product-card-horizontal__title">{{ title }}</span>
          <div class="sf-product-card-horizontal__bottom-wrapper">
            <slot name="price" v-bind="{ specialPrice, regularPrice }">
              <SfPrice
                :class="{ 'display-none': !regularPrice }"
                class="sf-product-card-horizontal__price"
                :regular="regularPrice"
                :special="specialPrice"
              />
            </slot>
          </div>
        </div>
      </SfButton>
    </template>
    <template v-else>
      <div class="sf-product-card-horizontal__image-wrapper">
        <slot
          name="image"
          v-bind="{
          image,
          title,
          link,
          imageHeight,
          imageWidth,
          imageTag,
        }"
        >
          <SfButton
            :link="link"
            class="sf-button--pure sf-product-card-horizontal__link"
            data-testid="product-link"
            aria-label="Go To Product"
            v-on="$listeners"
          >
            <template v-if="Array.isArray(image)">
              <SfImage
                v-for="(picture, key) in image.slice(0, 2)"
                :key="key"
                class="sf-product-card-horizontal__picture"
                :class="key !== 0 ? 'sf-product-card-horizontal__image-hover' : 'sf-product-card-horizontal__image-main'"
                :src="picture"
                :placeholder="placeholder"
                :alt="alt"
                :width="imageWidth"
                :height="imageHeight"
              />
            </template>
            <SfImage
              v-else
              class="sf-product-card-horizontal__image"
              :src="image"
              :alt="alt"
              :width="imageWidth"
              :height="imageHeight"
            />
            <slot name="badge" v-bind="{ badge }">
              <div class="sf-product-card-horizontal__badge-container" v-if="badge.length">
                <SfBadge v-for="badgeItem in badge" key="badgeItem"
                         class="sf-product-card-horizontal__badge"
                         :class="[{ 'display-none': !badgeItem }]"
                >{{ badgeItem }}
                </SfBadge>
              </div>
            </slot>
          </SfButton>
        </slot>
        <slot
          v-if="!isProductPresentation"
          name="wishlist-icon"
          v-bind="{ currentWishlistIcon }"
        >
          <SfButton
            :title="isInWishlist ? $t('Remove from Wishlist') : $t('Add to Wishlist')"
            :aria-label="`${ariaLabel} ${title}`"
            class="sf-button--pure sf-product-card-horizontal__wishlist-icon"
            data-testid="product-wishlist-button"
            @click="toggleIsInWishlist"
          >
            <SvgImage
              v-if="currentWishlistIcon"
              class="sf-wishlist-icon"
              :icon="currentWishlistIcon"
              :label="$t('Wishlist')"
              width="12"
              height="18"
            />
          </SfButton>
        </slot>
        <div class="mobile-only">
          <slot name="quantity-selector" />
        </div>
      </div>
      <div class="sf-product-card-horizontal__info-wrapper">
        <div class="sf-product-card-horizontal__title-wishlist-wrapper">
          <slot name="title" v-bind="{ title, link }">
            <SfButton
              :link="link"
              class="sf-button--pure sf-product-card-horizontal__link"
              data-testid="product-link"
              v-on="$listeners"
            >
            <span class="sf-product-card-horizontal__title" :title="title">
              {{ title }}
            </span>
            </SfButton>
          </slot>
        </div>
        <slot name="short_description" v-bind="{ short_description }">
          <p class="sf-product-card-horizontal__short-description"
             :class="{ 'display-none': !short_description }">
            {{ short_description }}
          </p>
        </slot>
        <slot name="tag_line" v-bind="{ tag_line }">
          <p class="sf-product-card-horizontal__tag_line"
             :class="{ 'display-none': !tag_line }">
            {{ tag_line }}
          </p>
        </slot>
        <slot name="size-selector" />
        <div class="sf-product-card-horizontal__bottom-wrapper">
          <slot name="price" v-bind="{ specialPrice, regularPrice }">
            <SfPrice
              :class="{ 'display-none': !regularPrice }"
              class="sf-product-card-horizontal__price"
              :regular="regularPrice"
              :special="specialPrice"
            />
          </slot>
        </div>
        <slot name="reviews" v-bind="{ maxRating, scoreRating }">
          <div
            :class="{ 'display-none': !scoreRating }"
            class="sf-product-card-horizontal__reviews"
          >
            <SfRating
              v-if="typeof scoreRating === 'number'"
              class="sf-product-card-horizontal__rating"
              :max="maxRating"
              :score="scoreRating"
            />
            <SfButton
              :class="{ 'display-none': !reviewsCount }"
              :aria-label="`Read ${reviewsCount} reviews about ${title}`"
              class="sf-button--pure sf-product-card-horizontal__reviews-count"
              data-testid="product-review-button"
              @click="$emit('click:reviews')"
            >
              ({{ reviewsCount }})
            </SfButton>
          </div>
        </slot>
        <slot name="remove-from-wishlist-button" v-bind="{ removeFromWishlistButton }">
          <SfButton
            :class="{ 'display-none': !removeFromWishlistButton }"
            class="sf-button--pure sf-product-card-horizontal__remove-from-wishlist"
            @click="$emit('click:wishlist')"
          >
            {{ $i18n.t('Remove from wishlist') }}
          </SfButton>
        </slot>
        <div class="desktop-only">
          <slot name="quantity-selector" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { colorsValues as SF_COLORS } from '~/styles/variables/colors';
import {
  SfIcon,
  SfPrice,
  SfRating,
  SfImage,
  SfCircleIcon,
  SfBadge,
  SfButton,
  SfColorPicker,
  SfColor,
  SfDropdown,
  SfSelect,
  SvgImage,
} from '~/components';
import imagePlaceholder from '~/styles/images/product_placeholder.svg';

export default {
  name: 'SfProductCardHorizontal',
  components: {
    SfPrice,
    SfRating,
    SfIcon,
    SfImage,
    SfCircleIcon,
    SfBadge,
    SfButton,
    SfColorPicker,
    SfColor,
    SfDropdown,
    SfSelect,
    SvgImage,
  },
  props: {
    sku: {
      type: String,
      default: '',
    },
    dataIndex: {
      type: [Number, String],
      default: '',
    },
    image: {
      type: [Array, Object, String],
      default: '',
    },
    imageWidth: {
      type: [Number, String],
      default: null,
    },
    imageHeight: {
      type: [Number, String],
      default: null,
    },
    placeholder: {
      type: String,
      default: imagePlaceholder,
    },
    badge: {
      type: Array,
      default: () => [],
    },
    badgeColor: {
      type: String,
      default: '',
    },
    colors: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    short_description: {
      type: String,
      default: '',
    },
    tag_line: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: null,
    },
    /**
     * Link element tag
     * @deprecated will be removed in 1.0.0 use slot to replace content
     */
    linkTag: {
      type: String,
      default: undefined,
    },
    scoreRating: {
      type: [Number, Boolean],
      default: false,
    },
    reviewsCount: {
      type: [Number, Boolean],
      default: false,
    },
    maxRating: {
      type: [Number, String],
      default: 5,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: 'addToWishlist',
    },
    isInWishlistIcon: {
      type: [String, Array],
      default: 'addedToWishlistWhite',
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    removeFromWishlistButton: {
      type: Boolean,
      default: false,
    },
    showAddToCartButton: {
      type: Boolean,
      default: false,
    },
    isAddedToCart: {
      type: Boolean,
      default: false,
    },
    addToCartDisabled: {
      type: Boolean,
      default: false,
    },
    isProductPresentation: {
      type: Boolean,
      default: false,
    },
    imageTag: {
      type: String,
      default: 'img',
      validator: (value) =>
        ['', 'img', 'nuxt-img', 'nuxt-picture'].includes(value),
    },
  },
  emits: ['click:wishlist'],
  data() {
    return {
      isAddingToCart: false,
      openColorPicker: false,
      selectedProduct: null
    };
  },
  computed: {
    isSFColors() {
      return SF_COLORS.includes(this.badgeColor.trim());
    },
    badgeColorClass() {
      return this.isSFColors ? `${this.badgeColor.trim()}` : '';
    },
    currentWishlistIcon() {
      return this.isInWishlist ? this.isInWishlistIcon : this.wishlistIcon;
    },
    showAddedToCartBadge() {
      return !this.isAddingToCart && this.isAddedToCart;
    },
    ariaLabel() {
      return this.isInWishlist ? 'Remove from wishlist' : 'Add to wishlist';
    },
    showBadge() {
      return this.colors.length > 5;
    },
    alt() {
      return this.title ? this.title : ' ';
    }
  },
  methods: {
    toggleIsInWishlist() {
      this.$emit('click:wishlist', !this.isInWishlist);
    }
  },
};
</script>

<style lang="scss">
@import "~@/styles/components/organisms/SfProductCardHorizontal.scss";
</style>
