import { Logger } from '~/helpers/logger';
import { ClearCartInput, Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import { CustomQuery, CustomHeaders } from '~/types/core';

export const clearCartCommand = {
  execute: async (
    context: VsfContext,
    {
      currentCart,
      customQuery,
      customHeaders,
    },
  ) => {
    Logger.debug('[Magento]: Clear cart');

    const clearCartParams: ClearCartInput = {
      uid: currentCart as string,
    };

    const { data } = await context.$magento.api.clearCart(
      clearCartParams,
      customQuery as CustomQuery,
      customHeaders as CustomHeaders,
    );

    Logger.debug('[Clear cart Result]:', { data });

    return data.clearCart.cart as unknown as Cart;
  },
};
