





































import {
  defineComponent,
  PropType,
  useContext,
  useRouter
} from '@nuxtjs/composition-api';

import {
  SfButton,
  SfHeading
} from '~/components';

import type { NuxtError } from '@nuxt/types';

export default defineComponent({
  components: {
    SfButton,
    SfHeading
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
  head() {
    return {
      title: this.$i18n.t('Error') as string
    };
  },
  setup(props) {
    const router = useRouter();
    const { localeRoute } = useContext();

    const handleGetBack = () => {
      if (props.error['statusCode'] === 404) {
        router.push(localeRoute({ name: 'home' }));
      } else {
        window.open(window.location.origin, '_self');
      }
    };

    return {
      handleGetBack
    };
  }
});
