export default `
  query getCategoryContentData($filters: CategoryFilterInput) {
    categoryList(filters: $filters) {
      uid
      display_mode
      landing_page
      is_quickshop
      cms_block {
        identifier
        content
      }
      collection_layout {
        layout_id
        type
        label
        category_id
        type
        is_active
        title
        subtitle
        button_text
        content
        hero_image_desktop_url
        hero_image_mobile_url
        video_desktop_url
        video_desktop_poster_url
        video_mobile_url
        video_mobile_poster_url
        image_desktop_url
        image_mobile_url
      }
    }
  }
`;
