export default `
{
    navigationMenuTreeList( filter: { identifier: { eq: "header_navigation" } is_enabled: { eq: true } }) {
        items {
            children {
                css_classes
                image_desktop_url
                image_mobile_url
                image_url
                image_url_title
                label
                level
                url
                url_type
                children {
                    css_classes
                    image_desktop_url
                    image_mobile_url
                    image_url
                    image_url_title
                    label
                    level
                    url
                    url_type
                    children {
                        css_classes
                        image_desktop_url
                        image_mobile_url
                        image_url
                        image_url_title
                        label
                        level
                        url
                        url_type
                        children {
                            css_classes
                            image_desktop_url
                            image_mobile_url
                            image_url
                            image_url_title
                            label
                            level
                            url
                            url_type
                        }
                    }
                }
            }
        }
    }
}
`;
