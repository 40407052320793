














































































import { defineComponent, nextTick, onMounted, ref } from '@nuxtjs/composition-api';
import { SfIcon, SfImage } from '~/components';
import { useApi, useConfig, useMap } from '~/composables';
import storeGetters from '~/modules/boutiques/getters/storeGetters';
import boutiqueQuery from '~/modules/boutiques/queries/getBoutiqueByName';

import Swiper, { Pagination } from 'swiper';

Swiper.use([Pagination]);

export default defineComponent({
  name: 'BoutiqueSingle',
  components: {
    SfIcon,
    SfImage,
  },
  props: {
    BoutiqueName: {
      type: String,
      required: true
    }
  },
  setup(props, { refs }) {
    const { query } = useApi();
    const boutique = ref(null);
    const { createMap, setMarkers } = useMap();
    const { config } = useConfig();
    const loading = ref(true);

    const initSwiper = () => {
      new Swiper(refs.boutiqueEl as HTMLElement, {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          dynamicBullets: true,
          dynamicMainBullets: 5,
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"><span class="swiper-pagination-bullet-inner"> </span></span>';
          }
        },
      });
    };

    onMounted(async () => {
      try {
        const result = await query(boutiqueQuery, { name: props.BoutiqueName });
        boutique.value = result.data['boutiques'].items[0];

        if (boutique.value) {
          nextTick(async () => {
            await createMap(refs.map,
              config.value.locale.slice(0, 2),
              { center: { lat: +boutique.value.address.latitude, lng: +boutique.value.address.longitude }, zoom: 16 },
              [{
                'elementType': 'labels.icon',
                'stylers': [{ 'visibility': 'on' }, { 'color': '#333333' }, { 'lightness': 40 }]
              }]
            );
            await setMarkers(boutique.value);
            initSwiper();
          });
        } else {
          console.error(`Boutique with name "${props.BoutiqueName}" not found`);
        }
      } finally {
        loading.value = false;
      }
    });

    return {
      loading,
      boutique,
      ...storeGetters,
    };
  },
});
