<template>
  <SfInput
      ref="searchInput"
      v-focus
      class="sf-search-bar"
      type="search"
      name="search"
      :value="value"
      :placeholder="placeholder"
      :icon="icon"
      v-bind="$attrs"
      v-on="listeners"
      @keyup.enter="$emit('input', value)"
  >
    <template #icon>
      <slot name="icon" v-bind="{ icon }" />
    </template>
    <template #loading>
      <slot name="loading" />
    </template>
  </SfInput>
</template>
<script>
import { SfInput } from '~/components';
import { focus } from '~/utilities/directives';

export default {
  name: 'SfSearchBar',
  components: {
    SfInput
  },
  directives: {
    focus,
  },
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: null,
    },
    icon: {
      type: Object,
      default: () => ({}),
    },
    isSearchOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        'keyup.esc': () => this.$emit('input', ''),
        blur: () => this.$emit('blur'),
        'click:icon': () => this.$emit('click:icon', this.value),
      };
    },
  },
  watch: {
    isSearchOpen: {
      handler(value) {
        value && this.$refs.searchInput.$refs.input.focus();
      },
    },
  }
};
</script>
<style lang="scss">
@import "~@/styles/components/molecules/SfSearchBar.scss";
</style>
