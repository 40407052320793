import { defineStore } from 'pinia';
import { CountrySelectorList } from '~/modules/GraphQL/types';

interface CountriesState {
  rawCountries: CountrySelectorList | null
}

export const useCountriesStore = defineStore('countries', {
  state: (): CountriesState => ({
    rawCountries: null
  }),
  actions: {
    async load() {

      const { data }: { data: { countrySelector?: CountrySelectorList } } = await this.$nuxt.app.$vsf.$magento.api.getCountries();
      this.rawCountries = data?.countrySelector ?? null;
    },
  },
  getters: {
    countries(state) {
      if (state.rawCountries === null) {
        return null;
      }

      return state.rawCountries?.items;
    },
  },
});
