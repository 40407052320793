<template>
  <div :class="classFromProps" class="sf-skeleton">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'SfSkeleton',
  props: {
    type: {
      type: String,
      default: 'paragraph',
      validator: (value) => ['paragraph', 'image', 'button', 'input', 'avatar', 'price', 'stock'],
    },
  },
  computed: {
    classFromProps() {
      return `sf-skeleton--${this.type}`;
    },
  },
};
</script>
<style lang="scss">
@import "~@/styles/components/atoms/SfSkeleton.scss";
</style>
