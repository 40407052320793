<template>
  <HTMLContent
    wrapper-class="product-care"
    :content="care"
  />
</template>
<script>
import HTMLContent from '~/components/HTMLContent.vue';

export default {
  name: 'ProductCare',
  components: {HTMLContent},
  props: {
    care: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
