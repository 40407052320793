















import { defineComponent, computed } from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'AddToWishlist',
  components: {
    SvgImage,
  },
  emits: ['add-to-wish-list-me'],
  props: {
    component: {
      type: String,
      default: 'span',
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    wishlistIcon: {
      type: String,
      default: 'addToWishlist',
    },
    isInWishlistIcon: {
      type: String,
      default: 'addedToWishlist',
    },
  },
  setup(props) {
    const actionText = computed(() => (props.isInWishlist ? 'Remove from Wishlist' : 'Add to Wishlist'));

    return {
      actionText,
    };
  },
});
