<template>
  <section class="sf-banner" :style="style" v-on="$listeners">
    <component :is="wrapper" class="sf-banner__wrapper" :link="link">
      <slot name="subtitle" v-bind="{ subtitle }">
        <span
            :class="{ 'display-none': !subtitle }"
            class="sf-banner__subtitle"
        >
          {{ subtitle }}
        </span>
      </slot>
      <slot name="title" v-bind="{ title }">
        <span :class="{ 'display-none': !title }" class="sf-banner__title">
          {{ title }}
        </span>
      </slot>
      <slot name="description" v-bind="{ description }">
        <span
            :class="{ 'display-none': !description }"
            class="sf-banner__description"
        >
          {{ description }}
        </span>
      </slot>
      <slot name="call-to-action" v-bind="{ buttonText }" />
    </component>
    <div class="sf-banner__wrapper-desktop" :link="link">
      <slot name="subtitle" v-bind="{ subtitle }">
        <span
            :class="{ 'display-none': !subtitle }"
            class="sf-banner__subtitle"
        >
          {{ subtitle }}
        </span>
      </slot>
      <slot name="title" v-bind="{ title }">
        <span :class="{ 'display-none': !title }" class="sf-banner__title">
          {{ title }}
        </span>
      </slot>
      <slot name="description" v-bind="{ description }">
        <span
            :class="{ 'display-none': !description }"
            class="sf-banner__description"
        >
          {{ description }}
        </span>
      </slot>
      <slot name="call-to-action" v-bind="{ buttonText }">
        <SfButton
            v-if="buttonText"
            :link="link"
            class="sf-banner__call-to-action color-secondary"
            data-testid="banner-cta-button"
            v-on="$listeners"
        >
          {{ buttonText }}
        </SfButton>
      </slot>
    </div>
    <slot name="img-tag" />
  </section>
</template>
<script>
import { SfButton, SfLink } from '~/components'

export default {
  name: "SfBanner",
  components: {
    SfButton,
    SfLink,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: null,
    },
    background: {
      type: String,
      default: "",
    },
    image: {
      type: [String, Object],
      default: "",
    },
    imageTag: {
      type: String,
      default: null,
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    style() {
      const image = this.image;
      const background = this.background;
      const nuxtImgConvert = (imgUrl) => {
        return `url(${this.$img(imgUrl, this.nuxtImgConfig)})`;
      };
      if(this.imageTag === "nuxt-img" || this.imageTag === "nuxt-picture") {
        return {
          "--_banner-background-image": image.mobile
              ? nuxtImgConvert(image.mobile)
              : nuxtImgConvert(image),
          "--_banner-background-desktop-image":
              image.desktop && nuxtImgConvert(image.desktop),
          "--_banner-background-color": background,
        };
      }
      return {
        "--_banner-background-image": image.mobile
            ? `url(${image.mobile})`
            : `url(${image})`,
        "--_banner-background-desktop-image":
            image.desktop && `url(${image.desktop})`,
        "--_banner-background-color": background,
      };
    },
    wrapper() {
      return this.link ? "SfLink" : "SfButton";
    },
  },
};
</script>
<style lang="scss">
@import "~@/styles/components/molecules/SfBanner.scss";
</style>
