<template>
  <div class="sf-tooltip"
  >
    <SfButton
      v-if="content.trim()"
      class="sf-tooltip__button sf-button--pure"
      @click="handleClick"
    >
      <SfIcon
        class="sf-tooltip__button-icon"
        :icon="isOpen ? closerIcon : openerIcon"
        :color="iconColor"
        :size="iconSize"
      />
    </SfButton>
    <div
      class="sf-tooltip__content"
      style="position: absolute; display: none;"
      ref="content"
      v-html="content"
    />
  </div>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton, SfIcon } from '~/components';

export default defineComponent({
  name: 'SfTooltip',
  components: {
    SfButton,
    SfIcon,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    openerIcon: {
      type: String,
      default: 'tooltip_opener',
    },
    closerIcon: {
      type: String,
      default: 'tooltip_closer',
    },
    iconColor: {
      type: String,
      default: 'var(--togas-gold-color)',
    },
    iconSize: {
      type: String,
      default: '32px',
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    handleClick() {
      if (this.isOpen) {
        this.$refs.content.style.display = 'none';
        this.isOpen = false;
      } else {
        this.$refs.content.style.display = 'block';
        this.isOpen = true;
      }
    }
  }
})
</script>

<style lang="scss">
@import "~@/styles/components/molecules/SfTooltip.scss";
</style>
