import { ref, useContext } from "@nuxtjs/composition-api";
import { useApi } from "~/composables/";

export function useBlog() {
  const { app } = useContext();
  const loadingCategories = ref<Boolean>(false);
  const loadingPosts = ref<Boolean>(false);
  const loadingPost = ref<Boolean>(false);

  const loadCategories = async (params = {}) => {
    let result = ref();
    try {
      loadingCategories.value = true;
      // @ts-ignore
      result.value = await app.$vsf.$magento.api.getBlogCategories();

    } catch (error) {
      console.warn('Error: ', error);
    } finally {
      loadingCategories.value = false;
    }

    return result;
  };

  const loadPosts = async (params) => {
    let result = ref();

    const categoryFilter =
      params.filters.category_id?.length
        ? { category_id: { in: [...params.filters.category_id] } }
        : {}
    ;

    try {
      loadingPosts.value = true;
      // @ts-ignore
      result.value = await app.$vsf.$magento.api.getBlogPosts({
        filter: categoryFilter,
        currentPage: params.page,
        pageSize: 9
      });
    } catch (error) {
      console.warn('Error: ', error);
    } finally {
      loadingPosts.value = false;
    }

    return result;
  };

  const loadPost = async (postId: string) => {
    let result = ref();

    try {
      loadingPost.value = true;
      // @ts-ignore
      result.value = await app.$vsf.$magento.api.getBlogPost({ postId: postId });
    } catch (error) {
      console.warn('Error: ', error);
    } finally {
      loadingPost.value = false;
    }

    return result;
  };

  return {
    loadCategories,
    loadPosts,
    loadPost,
    loadingCategories,
    loadingPosts,
    loadingPost
  }
}

export default useBlog;
