import { ref, readonly, Ref } from '@nuxtjs/composition-api';
import { useApi } from '~/composables';

/**
 * Позволяет получать данные с помощью запросов GraphQL.
 */
type UseDataFetchResponse = {
  loadData: (queryName: string, variables: object) => any;
  loading: Readonly<Ref<boolean>>;
  error: Readonly<Ref<Error | null>>;
}

export function useDataFetch(): UseDataFetchResponse {
  const { query } = useApi();
  const loading = ref(false);
  const error = ref(null);
  const data = ref(null);

  const loadData = async (queryName: string, variables: object | null = null) => {
    try {
      loading.value = true;
      data.value = await query(queryName, variables);
    } catch (err) {
      error.value = err;
      console.error('Ошибка в useDataFetch:', err);
    } finally {
      loading.value = false;
    }

    return data.value;
  };

  return {
    loadData,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useDataFetch;
