<template>
  <div v-if="categoriesData" class="giftguide">
    <SfHeading :level="2" :styleLevel="1" :title="categoriesData.name" class="giftguide__title desktop-only" />
    <SfLink
      v-for="category in categoriesData.children"
      :key="category.url_key"
      :link="decodeURIComponent(localePath(getCatLink(category)))"
      class="giftguide__link"
    >
      <SfImage
        class="giftguide__image"
        image-tag="img"
        :alt="category.name"
        :src="category.image"
        preset="default"
      />
      <div class="giftguide__overlay mobile-only" />
      <SfHeading :level="3" :styleLevel="4" :title="category.name" class="giftguide__category--title desktop-only" />
      <div class="giftguide__category--content mobile-only">
        <div class="giftguide__category--description">{{ categoriesData.name }}</div>
        <SfHeading :level="3" :title="category.name" class="giftguide__category--name" />
        <span class="giftguide__category--text">{{ $i18n.t('Shop now') }}</span>
      </div>
    </SfLink>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import { useUiHelpers, useApi } from '~/composables';
import { SfImage, SfLink, SfHeading } from '~/components';
import { giftGuideRequest } from '~/customQueries/giftGuideRequest';

export default defineComponent({
  name: 'GiftGuide',
  components: {
    SfLink,
    SfImage,
    SfHeading
  },
  setup() {
    const {getCatLink} = useUiHelpers();
    const {query} = useApi();

    const categoriesData = ref(null);

    onMounted(async () => {
      try {
        const {data} = await query(giftGuideRequest);
        categoriesData.value = data.categoryList[0];
      }
      catch (error) {
        console.error('Error in GiftGuide.vue:', error);
      }
    });

    return {
      categoriesData,
      getCatLink,
    };
  },
});
</script>

<style lang="scss" scoped>
.giftguide {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  gap: var(--spacer-2xs) 0;
  --link-font-family: var(--font-family--secondary);
  --link-color: var(--togas-white-color);
  --c-link-hover: var(--togas-white-color);
  --image-width: 100%;

  @include for-desktop {
    grid-template-columns: repeat(4, 1fr);
    gap: 0 var(--spacer-sm);
    margin-bottom: var(--spacer-lg);
    --link-color: var(--togas-black-color);
  }

  &__title {
    grid-column: 1/5;
    margin: 0 0 var(--spacer-base) 0;
  }

  &__link {
    display: flex;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
    font-size: var(--font-size--xl);
    line-height: var(--line-height--xxl);
    font-weight: var(--font-weight--bold);
    position: relative;
  }

  &__image {
    ::v-deep .sf-image {
      aspect-ratio: 365 / 205;

      @include for-desktop() {
        aspect-ratio: 323 / 240;
      }
    }

    @include for-desktop() {
      margin-bottom: var(--spacer-base);
    }
  }

  &__overlay {
    background: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__category {

    &--content {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: var(--spacer-base) var(--spacer-sm) var(--spacer-sm) var(--spacer-sm);
    }

    &--description {
      margin-bottom: var(--spacer-2xs);
    }

    &--name {
      ::v-deep .sf-heading__title {
        --heading-color: var(--togas-white-color);
      }
    }

    &--text {
      font-family: var(--font-family--primary);
      font-size: var(--font-size--sm);
      font-weight: var(--font-weight--normal);
      line-height: var(--line-height--xs);
      letter-spacing: 2px;
      border-bottom: 1px solid var(--togas-white-color);
      color: var(--togas-white-color);
      margin: auto 0 0;
    }
  }
}
</style>
