<template>
  <div class="sf-dropdown" v-click-outside="checkPersistence">
    <slot name="opener">
      <SfButton
          class="sf-button--pure sf-dropdown__opener"
          :aria-label="openerText"
          @click="handler"
      >
        {{ openerText}}
        <SvgImage
          v-if="!isDropdownOpen && openerIcon"
          class="sf-dropdown__opener-icon"
          :icon="openerIconOpen"
          :width="openerIconOpenWidth"
          :height="openerIconOpenHeight"
        />
        <SvgImage
          v-if="isDropdownOpen && openerIcon"
          class="sf-dropdown__opener-icon"
          :icon="openerIconClose"
          :width="openerIconCloseWidth"
          :height="openerIconCloseHeight"
        />
      </SfButton>
    </slot>
    <transition name="sf-dropdown">
      <div
        v-show="isDropdownOpen"
        class="sf-dropdown__container"
      >
        <slot name="title" v-bind="{ title }">
          <div :class="{ 'display-none': !title }" class="sf-dropdown__title">
            {{ title }}
          </div>
        </slot>
        <slot />
      </div>
    </transition>
  </div>
</template>
<script>
import { clickOutside } from '~/utilities/directives/'
import { SfButton, SfOverlay, SvgImage } from '~/components'
export default {
  name: 'SfDropdown',
  components: {
    SfOverlay,
    SfButton,
    SvgImage
  },
  directives: {
    clickOutside,
  },
  data() {
    return {
      isDropdownOpen: this.isOpen,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    openerText: {
      type: String,
      default: '',
    },
    openerIcon: {
      type: Boolean,
      default: true,
    },
    openerIconOpen: {
      type: String,
      default: 'chevron_down',
    },
    openerIconOpenHeight: {
      type: String,
      default: '12',
    },
    openerIconOpenWidth: {
      type: String,
      default: '12',
    },
    openerIconClose: {
      type: String,
      default: 'chevron_up',
    },
    openerIconCloseHeight: {
      type: String,
      default: '12',
    },
    openerIconCloseWidth: {
      type: String,
      default: '12',
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.emitInterface();
  },
  methods: {
    checkPersistence() {
      if(!this.persistent) {
        this.closeHandler();
      }
    },
    handler() {
      if (this.isDropdownOpen) {
        this.closeHandler();
        return;
      }
      this.openHandler();
    },
    closeHandler() {
      this.isDropdownOpen = false;
    },
    openHandler() {
      this.isDropdownOpen = true;
    },
    keydownHandler(e) {
      if(e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        this.closeHandler()
      }
    },
    emitInterface() {
      this.$emit("interface", {
        handler: () => this.handler()
      });
    }
  },
}
</script>
<style lang="scss">
@import "~@/styles/components/molecules/SfDropdown.scss";
</style>
