<template functional>
  <component
      :is="injections.components.SfButton"
      :class="[
      data.class,
      data.staticClass,
      'sf-button--pure sf-color',
      { 'is-active': props.selected },
    ]"
      :style="{
      ...data.style,
      ...data.staticStyle,
      '--color-background': props.color,
    }"
      :aria-pressed="props.selected.toString()"
      :data-testid="props.color"
      v-bind="data.attrs"
      v-on="listeners"
  >
    <transition name="sf-bounce">
      <slot name="badge" v-bind="{ props }">
        <component
            :is="injections.components.SfBadge"
            :class="{
            'display-none':
              !props.hasBadge || (!props.selected && props.hasBadge),
          }"
            class="sf-color__badge mobile-only"
        >
          <component
              :is="injections.components.SfIcon"
              size="16px"
              color="white"
              icon="check"
              aria-hidden="true"
          />
        </component>
      </slot>
    </transition>
  </component>
</template>
<script>
import SfBadge from '~/components/atoms/SfBadge/SfBadge.vue';
import SfButton from '~/components/atoms/SfButton/SfButton.vue';
import SfIcon from '~/components/atoms/SfIcon/SfIcon.vue';

export default {
  name: 'SfColor',
  inject: {
    components: {
      default: {
        SfBadge,
        SfIcon,
        SfButton,
      },
    },
  },
  props: {
    color: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    hasBadge: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style lang="scss">
@import "~@/styles/components/atoms/SfColor";
</style>
