var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sf-checkbox",class:{
    'is-active': _vm.isChecked,
    'is-disabled': _vm.disabled,
    'has-error': !_vm.valid,
    'is-required': _vm.required,
  },attrs:{"data-testid":_vm.name}},[_c('label',{staticClass:"sf-checkbox__container"},[_c('input',{directives:[{name:"focus",rawName:"v-focus"}],staticClass:"sf-checkbox__input",attrs:{"type":"checkbox","name":_vm.name,"disabled":_vm.disabled,"aria-invalid":!_vm.valid,"aria-required":_vm.required,"aria-describedby":_vm.errorMessage ? (_vm.nameWithoutWhitespace + "-error") : null},domProps:{"value":_vm.value,"checked":_vm.isChecked},on:{"change":_vm.inputHandler}}),_vm._v(" "),_vm._t("checkmark",function(){return [_c('span',{staticClass:"sf-checkbox__checkmark",class:{ 'sf-checkbox__checkmark is-active': _vm.isChecked }},[_c('span',{staticClass:"sf-checkbox__checkmark",class:{ 'sf-sf-checkbox__checkmark is-active': _vm.isChecked }})])]},null,{ isChecked: _vm.isChecked, disabled: _vm.disabled }),_vm._v(" "),_vm._t("label",function(){return [_c('span',{staticClass:"sf-checkbox__label",class:{
          'display-none': !_vm.label,
          'sf-checkbox__label--is-disabled': _vm.disabled,
         }},[_vm._v("\n        "+_vm._s(_vm.label)+"\n      ")])]},null,{ label: _vm.label, isChecked: _vm.isChecked, disabled: _vm.disabled })],2),_vm._v(" "),_c('div',{staticClass:"sf-checkbox__message"},[_c('transition',{attrs:{"name":"sf-fade"}},[(!_vm.disabled)?_vm._t(_vm.computedMessageSlotName,function(){return [_c('div',{class:_vm.computedMessageClass,attrs:{"id":(_vm.nameWithoutWhitespace + "-error"),"aria-live":"assertive"}},[_vm._v("\n          "+_vm._s(_vm.computedMessage)+"\n        ")])]},null,{ computedMessage: _vm.computedMessage }):_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }