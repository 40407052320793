<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1836_61573)">
      <rect x="3.48773" y="17.4374" width="5.65161" height="19.2" rx="1.2" transform="rotate(-123 3.48773 17.4374)"
            stroke="#717171" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
      <rect x="4.06787" y="13.1418" width="0.645161" height="3.68664" rx="0.322581"
            transform="rotate(-33 4.06787 13.1418)" fill="#717171" />
      <rect x="7.52588" y="12.5444" width="0.645161" height="2.30415" rx="0.322581"
            transform="rotate(-33 7.52588 12.5444)" fill="#717171" />
      <rect x="9.47852" y="9.62793" width="0.645161" height="3.68664" rx="0.322581"
            transform="rotate(-33 9.47852 9.62793)" fill="#717171" />
      <rect x="12.937" y="9.03052" width="0.645161" height="2.30415" rx="0.322581"
            transform="rotate(-33 12.937 9.03052)" fill="#717171" />
      <rect x="14.8892" y="6.11426" width="0.645161" height="3.68664" rx="0.322581"
            transform="rotate(-33 14.8892 6.11426)" fill="#717171" />
    </g>
    <defs>
      <clipPath id="clip0_1836_61573">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>

</template>

<script type="ts">
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SizeIcon',
});
</script>
