import { computed, Ref } from '@nuxtjs/composition-api';
import { getGallery as getProductGallery } from '~/modules/catalog/product/getters/productGetters';
import { getVideoGallery as getProductVideoGallery } from '~/modules/catalog/product/getters/productGetters';
import { useImage } from '~/composables';
import type { Product } from '~/modules/catalog/product/types';
import type {
  UseProductGalleryInterface
} from '~/modules/catalog/product/composables/useProductGallery/useProductGallery';

/**
 * The `useProductGallery()` composable allows building product's gallery data structure.
 *
 * See the {@link UseProductGalleryInterface} page for more information.
 */
export function useProductGallery(product: Ref<Product>, imgPlaceholder = ''): UseProductGalleryInterface {
  const { imageSizes } = useImage();
  const productGallery = computed(() => getProductGallery(product.value).map((img) => ({
    source_link: img.source_link,
    desktop: img.desktop,
    mobile: img.mobile,
    roles: img.roles,
    alt: product.value.name,
    placeholder: imgPlaceholder,
  })));

  const productVideoGallery = computed(() => getProductVideoGallery(product.value));

  return {
    productGallery,
    productVideoGallery,
    imageSizes,
  };
}

export default useProductGallery;
export * from './useProductGallery';
