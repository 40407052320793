export const sendEcommerce = ((eventMame, pageType, productData, additional = {}, ecommerceAdditional = {}) => {

  const getItemData = (product) => {
    return Object.assign(
      {
        'item_brand': 'Togas',
        'item_name': product.name,
        'item_id': product.sku,
        'price': product.configurable_product_options_selection?.variant?.price_range?.minimum_price?.final_price?.value
          || product.price_range?.minimum_price?.final_price.value,
        'currency': product.price_range?.minimum_price?.final_price.currency,
        'discount':
          (product.configurable_product_options_selection?.variant?.price_range?.minimum_price?.regular_price.value -
            product.configurable_product_options_selection?.variant?.price_range?.minimum_price?.final_price.value)
          || product.price_range?.minimum_price?.regular_price?.value - product.price_range?.minimum_price?.final_price.value,
        'item_variant': product.configurable_product_options_selection?.variant?.uid || '',
      },
      ...product.categories.map((e, i) => ({[`category${i + 1}`]: e.name})),
      product.item_list_name ? { item_list_name: product.item_list_name } : {},
      product.item_list_id ? { item_list_id: product.item_list_id } : {},
      product.quantity ? { quantity: product.quantity } : {},
    )
  };

  const getItemsData = (productData) => {
    const items = [];
    productData.forEach((item, index) => items.push(Object.assign(getItemData(item), { index: index + 1 })));
    return items;
  }

  window.dataLayer = window.dataLayer || [];
  let data = [];
  if (Array.isArray(productData)) {
    data = getItemsData(productData)
  } else {
    data.push(getItemData(productData))
  }
  window.dataLayer.push({
    'event': eventMame,
    'page_type': pageType,
    'ecommerce': {
      'items': [
        ...data
      ],
        ...ecommerceAdditional
    },
    ...additional
  })
});
