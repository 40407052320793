<template>
  <div class="sf-carousel">
    <div class="sf-carousel__wrapper">
      <div ref="glide" class="glide">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides sf-carousel__slides">
            <slot />
          </ul>
        </div>
      </div>
    </div>
    <div class="sf-carousel__controls sf-carousel__controls-prev">
      <slot name="prev"
            v-if="settings.controls"
            ref="controlsPrev"
            v-bind="{ go: () => go('prev') }"
            class="carousel-prev-button"
      >
        <SfArrow
          aria-label="previous"
          data-testid="carousel-prev-button"
          @click="go('prev')"
        />
      </slot>
    </div>
    <div class="sf-carousel__controls sf-carousel__controls-next">
      <slot name="next"
            v-if="settings.controls"
            ref="controlsNext"
            v-bind="{ go: () => go('next') }"
            class="carousel-next-button"
      >
        <SfArrow
          aria-label="next"
          class="sf-arrow--right"
          data-testid="carousel-next-button"
          @click="go('next')"
        />
      </slot>
    </div>

    <slot name="bullets"
          v-if="settings.dots && numberOfSlides > settings.slidePerPage"
          v-bind="{ numberOfSlides, slide, go }"
    >
      <SfBullets
        :class="settings.dotsClass"
        :total="numberOfSlides"
        :current="slide - 1"
        @click="go($event)"
      />
    </slot>
  </div>
</template>
<script>
import Vue from 'vue';
import { SfArrow, SfCarouselItem, SfBullets } from '~/components';

import Glide from '@glidejs/glide';

Vue.component('SfCarouselItem', SfCarouselItem)
export default {
  name: 'SfCarousel',
  components: {
    SfArrow,
    SfBullets,
  },
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
    carouselRef: {
      type: String,
      default: () => 'glide',
    },
  },
  data() {
    return {
      glide: null,
      defaultSettings: {
        type: 'carousel',
        rewind: true,
        dots:  false,
        controls:  false,
        breakpoints: {},
      },
    }
  },
  computed: {
    mergedOptions() {
      let breakpoints = {...this.defaultSettings.breakpoints}
      if(this.settings.breakpoints) {
        breakpoints = {...breakpoints, ...this.settings.breakpoints}
      }
      return {
        ...this.defaultSettings,
        ...this.settings,
        breakpoints: breakpoints,
      }
    },
    computedRef() {
      return this.carouselRef;
    },
    numberOfSlides() {
      return this.$slots.default
        ? this.$slots.default.filter((slot) => slot.tag).length
        : 0;
    },
    slide() {
      if(this.glide) {
        return this.glide.index + 1;
      }
      return 1;
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      if(!this.$slots.default) return
      const glide = new Glide(this.$refs.glide, this.mergedOptions)
      const size = this.$slots.default.filter((slot) => slot.tag).length
      if(size <= glide.settings.perView) {
        glide.settings.perView = size
        glide.settings.rewind = false
        // this.$refs.controlsPrev.style.display = 'none'
        // this.$refs.controlsNext.style.display = 'none'
      }
      glide.mount()
      glide.on('run.before', (move) => {
        const {slidePerPage, rewind, type} = this.mergedOptions
        if(!slidePerPage) return
        const {perView} = glide.settings
        if(!perView > 1) return
        const {direction} = move
        let page, newIndex
        switch (direction) {
          case '>':
          case '<':
            page = Math.ceil(glide.index / perView)
            newIndex =
                page * perView + (direction === '>' ? perView : -perView)
            if(newIndex >= size) {
              if(type === 'slider' && !rewind) {
                newIndex = glide.index
              } else {
                newIndex = 0
              }
            } else if(newIndex < 0 || newIndex + perView > size) {
              if(type === 'slider' && !rewind) {
                newIndex = glide.index
              } else {
                newIndex = size - perView
              }
            }
            move.direction = '='
            move.steps = newIndex
        }
      })
      this.glide = glide
    })
  },
  methods: {
    go(direct) {
      if(!this.glide) return
      switch (direct) {
        case 'prev':
          this.glide.go('<')
          break
        case 'next':
          this.glide.go('>')
          break;
        default:
          this.glide.go(`=${direct}`);
          break;
      }
    },
  },
}
</script>
<style lang="scss">
@import "~@/styles/components/organisms/SfCarousel.scss";
</style>
