export default `
query ($name: String!) {
  boutiques(filter: { name: { match: $name }}) {
    items {
      name
      contacts {
        type
        value
      }
      address {
        city
        country_name
        latitude
        longitude
        postcode
        region
        street
      }
      business_hours_output
      media_gallery {
        desktop_url
        mobile_url
        role
        sort_order
      }
    }
  }
}
`;
